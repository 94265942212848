import banner1 from "../Asset/Banner/banner1.jpg"
import banner2 from "../Asset/Banner/banner2.jpg"
import banner3 from "../Asset/Banner/banner3.jpg"
import banner4 from "../Asset/Banner/banner4.jpg"
import banner5 from "../Asset/Banner/banner5.jpg"
import banner6 from "../Asset/BAN1.jpg"
import banner7 from "../Asset/BAN2.jpg"
import banner8 from "../Asset/BAN3.jpg"
import banner9 from "../Asset/BAN4.jpg"
import banner10 from "../Asset/BAN5.jpg"
import banner11 from "../Asset/BAN6.jpg"
import banner12 from "../Asset/BAN7.jpg"
export const photos = [
    {
      src: banner1,
      width: 4,
      height: 3
    },
    {
      src: banner2,
      width: 1,
      height: 1
    },
    {
      src: banner3,
      width: 3,
      height: 4
    },
    {
      src: banner4,
      width: 3,
      height: 4
    },
    {
      src: banner5,
      width: 3,
      height: 4
    },
    {
      src: banner6,
      width: 4,
      height: 3
    },
    {
      src:banner7,
      width: 3,
      height: 4
    },
    {
      src: banner8,
      width: 4,
      height: 3
    },
    {
      src: banner9,
      width: 4,
      height: 3
    },
    {
      src: banner10,
      width: 4,
      height: 3
    },
    {
      src: banner11,
      width: 4,
      height: 3
    }
  ];
  