import banner1 from "../Asset/Banner/banner1.jpg";
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Enquiryform from "../Components/Enquiryform";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { MetaTags } from 'react-meta-tags';
import banner2 from "../Asset/Banner/banner2.jpg";
import banner3 from "../Asset/Banner/banner3.jpg";
import banner4 from "../Asset/Banner/banner4.jpg";
import banner5 from "../Asset/Banner/banner5.jpg";
import ban1 from "../Asset/Banner/ban1.jpeg"
import p1 from "../Asset/img1/p1.jpg"
import p2 from "../Asset/img1/p2.jpg"
import p3 from "../Asset/img1/p3.jpg"
import p4 from "../Asset/img1/p4.jpg"
import p5 from "../Asset/img1/p5.jpg"
import comann from "../Asset/Banner/bannerdemo1.jpeg"
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import Footer from "../Components/Footer";
const Decorebyevent = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div>
        <MetaTags>
        <title> Wedding Decorator Company In Malad | Celebrity Decorator</title>
        <meta title="  Wedding Decorator Company In Malad | Celebrity Decorator" />
        <meta name="description" content=" Experience the magic of our Wedding Decorator Company in Malad. From elegant themes to personalized touches, we specialize in transforming spaces." />
        <meta name="keywords" content="top-wedding-planner-decorator-malad,wedding-services-company-malad,destination-wedding-karjat,destination-wedding-company-malad,wedding-decorator-company-malad,wedding-theme-decorator-malad,wedding-venues-mumbai,best-wedding-bartenders-mumbai,best-wedding-caterers-mumbai,makeup-artists-wedding-mumbai,mehendi-artists-wedding-mumbai,wedding-invitation-cards-mumbai,best-wedding-photographers-videographers-mumbai,wedding-reception-bands-mumbai,comedians-weddings-mumbai,best-djs-weddings-mumbai,wedding-choreographer-hire-mumbai,wedding-host-mumbai,wedding-singers-mumbai,wedding-event-planner-mumbai" />
        <link rel="canonical" href="https://celebritydecorator.com/wedding-decorator-company-malad" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Wedding Decorator Company In Malad | Celebrity Decorator" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://celebritydecorator.com/" />
        <meta property="og:description" content=" Experience the magic of our Wedding Decorator Company in Malad. From elegant themes to personalized touches, we specialize in transforming spaces." />
        <meta property="og:image" content="https://skworld.in/static/media/logo.0a02f996c9ddeb40558c.png" />
      </MetaTags>
      <section
        id="breadcrumb-section"
        className="breadcrumb-section clearfix margintop"
      >
        <div
          className="jarallax"
          style={{
            backgroundImage: "none",
            zIndex: 0,
            backgroundImage: `url(${comann})`,
          }}
        >
          <div className="overlay-black">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* breadcrumb-title - start */}
                  <div className="breadcrumb-title text-center mb-50">
                    <span className="sub-title">Celebrity Decorator</span>
                    <h2 className="big-title">
                      <strong>DECORE BY EVENT</strong> 
                    </h2>
                  </div>
                  {/* breadcrumb-title - end */}
                  {/* breadcrumb-list - start */}
                  <div className="breadcrumb-list">
                    <ul>
                      <li className="breadcrumb-item">
                        <a href="index-1.html" className="breadcrumb-link">
                          Home
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        about us
                      </li>
                    </ul>
                  </div>
                  {/* breadcrumb-list - end */}
                </div>
              </div>
            </div>
          </div>
          <div
            id="jarallax-container-0"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              overflow: "hidden",
              pointerEvents: "none",
              zIndex: -100,
            }}
          >
            <div
              style={{
                backgroundPosition: "50% 50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${banner1})`,
                position: "fixed",
                backgroundSize:"100% 100%",
                top: 0,
                left: 0,

                height: "553.66px",
                overflow: "hidden",
                pointerEvents: "none",
                marginTop: "49.17px",
              }}
              className="backbanner"
            />
          </div>
        </div>
      </section>
      <section
        id="service-section"
        className="service-section sec-ptb-100 clearfix"
      >
        <div className="container">
          <div className="service-wrapper">
            <div className="service-item clearfix">
              <div className="row">
                <div className="col-md-7">
                  {" "}
                  <div className="  float-left">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      loop={true}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner1} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner2} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner3} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner4} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner5} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      loop={true}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner5} />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
                <div className="col-md-5">
                  {" "}
                  <div className="service-content float-right">
                    <div className="service-title mb-30">
                      <h2 className="title-text">
                        <strong className="colorwhite fontstyle">Mehendi</strong>
                      </h2>
                    </div>
                    <p
                      className="service-description black-color mb-30 colorwhite"
                      style={{ textAlign: "justify" }}
                    >
                    
Enhance your Malad wedding with the perfect touch of tradition and elegance by choosing our top-tier Mehendi services. As a leading wedding decorator company in Malad, we specialize in transforming venues into enchanting spaces that reflect your unique style. Our skilled team ensures meticulous attention to detail, creating a Mehendi celebration that is both visually stunning and culturally rich. From vibrant floral arrangements to intricate decor elements, we tailor every aspect to your preferences, ensuring a truly unforgettable experience. Trust our expertise to turn your Mehendi ceremony into a mesmerizing affair, blending modern sophistication with traditional charm. Choose us, the premier wedding decorator company in Malad, for an extraordinary celebration.
                    </p>

                    <div className="text-left">
                      <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                       enquiry now
                      </button>
                      <div className="modelpop" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-item clearfix">
              <div className="row">
                <div className="col-md-5 order-md-1 order-2">
                  <div className="service-content float-left">
                    <div className="service-title mb-30">
                      <h2 className="title-text">
                        <strong className="colorwhite fontstyle">Reception</strong>
                      </h2>
                    </div>
                    <p
                      className="service-description black-color mb-30 colorwhite"
                      style={{ textAlign: "justify" }}
                    >
                     If you're seeking an unparalleled wedding experience, look no further than our esteemed wedding decorator company in Malad. Elevate your celebration with our exquisite decor that seamlessly blends sophistication and style. Our seasoned team of experts transforms your dream wedding into a breathtaking reality, leaving an indelible mark on your special day. From enchanting floral arrangements to elegant drapery, every detail is meticulously curated to reflect your unique love story. Entrust us with your vision, and watch as we weave magic into every corner. Let our Malad-based wedding decorator company redefine elegance, ensuring your wedding is a masterpiece etched in memories forever.
                    </p>

                    <div className="text-left">
                      <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                       enquiry now
                      </button>
                      <div className="modelpop" />
                    </div>
                  </div>
                </div>
                <div className="col-md-7 order-md-2 order-1">
                  <div className=" float-right">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      loop={true}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={p1} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={p1} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner3} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner4} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner5} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      loop={true}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner5} />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-item clearfix">
              <div className="row">
                <div className="col-md-7">
                  {" "}
                  <div className="  float-left">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      loop={true}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={p3} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner2} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner3} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner4} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner5} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      loop={true}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner5} />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
                <div className="col-md-5">
                  {" "}
                  <div className="service-content float-right">
                    <div className="service-title mb-30">
                      <h2 className="title-text">
                        <strong className="colorwhite fontstyle">Sangeet </strong>
                      </h2>
                    </div>
                    <p
                      className="service-description black-color mb-30 colorwhite"
                      style={{ textAlign: "justify" }}
                    >
                     
Experience the epitome of elegance with our premier wedding decorator company in Malad. Elevate your special day with exquisite wedding decor that reflects your unique style and vision. Our team of skilled professionals is dedicated to transforming your dream wedding into a reality, creating a magical ambiance that leaves a lasting impression. From enchanting floral arrangements to stunning lighting designs, we specialize in curating the perfect atmosphere for your celebration. Trust our expertise to add a touch of sophistication to every detail, ensuring a memorable and visually stunning event. Choose us as your trusted wedding decorator in Malad and let your wedding day shine with timeless beauty and charm.
                    </p>

                    <div className="text-left">
                      <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                       enquiry now
                      </button>
                      <div className="modelpop" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-item clearfix">
              <div className="row">
                <div className="col-md-5 order-md-1 order-2">
                  <div className="service-content float-left">
                    <div className="service-title mb-30">
                      <h2 className="title-text">
                        <strong className="colorwhite fontstyle">Wedding</strong>
                      </h2>
                    </div>
                    <p
                      className="service-description black-color mb-30 colorwhite"
                      style={{ textAlign: "justify" }}
                    >
                      
Celebrate your special day with unparalleled elegance and enchanting decor provided by the premier wedding decorator company in Malad. Our expert team transforms your dreams into reality, crafting a magical atmosphere that reflects your unique style. From stunning floral arrangements to exquisite lighting, every detail is meticulously curated to make your wedding a truly unforgettable experience. With a commitment to perfection and a passion for creativity, we take pride in being the go-to wedding decorator in Malad. Trust us to add that extra touch of glamour and sophistication, ensuring your wedding is a breathtaking affair that leaves a lasting impression.
                    </p>

                    <div className="text-left">
                      <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                       enquiry now
                      </button>
                      <div className="modelpop" />
                    </div>
                  </div>
                </div>
                <div className="col-md-7 order-md-2 order-1">
                  <div className=" float-right">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      loop={true}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={p4} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner2} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner3} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner4} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner5} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      loop={true}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner5} />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-item clearfix">
              <div className="row">
                <div className="col-md-7">
                  {" "}
                  <div className="  float-left">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      loop={true}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner1} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner2} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner3} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner4} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner5} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      loop={true}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner5} />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
                <div className="col-md-5">
                  {" "}
                  <div className="service-content float-right">
                    <div className="service-title mb-30">
                      <h2 className="title-text">
                        <strong className="colorwhite fontstyle">After Party</strong>
                      </h2>
                    </div>
                    <p
                      className="service-description black-color mb-30 colorwhite"
                      style={{ textAlign: "justify" }}
                    >
                     
Elevate your wedding celebration with the expertise of the premier Wedding Decorator Company in Malad. Our dedicated team transforms your special day into a magical affair, curating enchanting setups that reflect your unique love story. Immerse your guests in a visual feast with our meticulous attention to detail and creative flair. From elegant floral arrangements to exquisite table settings, we ensure every element harmonizes seamlessly, creating an unforgettable ambiance. As the festivities unfold, our Wedding Decorators weave dreams into reality, turning your venue into a picturesque haven. Trust us to bring your vision to life, making your post-wedding party an exquisite extension of your love story.  
                    </p>

                    <div className="text-left">
                      <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                       enquiry now
                      </button>
                      <div className="modelpop" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-item clearfix">
              <div className="row">
                <div className="col-md-5 order-md-1 order-2">
                  <div className="service-content float-left">
                    <div className="service-title mb-30">
                      <h2 className="title-text">
                        <strong className="colorwhite fontstyle">Engagement</strong>
                      </h2>
                    </div>
                    <p
                      className="service-description black-color mb-30 colorwhite"
                      style={{ textAlign: "justify" }}
                    >
                      Transform your special day into a masterpiece with the premier Wedding Decorator Company in Malad. Elevate your wedding aesthetics with our expert team dedicated to creating unforgettable moments. Our Malad-based decorators blend creativity and precision to bring your dream wedding to life. From enchanting floral arrangements to exquisite drapery, we curate every detail to reflect your unique style. Entrust us with your vision, and watch as our skilled professionals craft a mesmerizing ambiance for your celebration. With a passion for perfection, our Wedding Decorator Company in Malad is committed to turning your wedding dreams into reality. Experience unparalleled elegance on your big day with our artistic touch. 
                    </p>

                    <div className="text-left">
                      <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                       enquiry now
                      </button>
                      <div className="modelpop" />
                    </div>
                  </div>
                </div>
                <div className="col-md-7 order-md-2 order-1">
                  <div className=" float-right">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      loop={true}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={p5} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner2} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner3} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner4} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner5} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      loop={true}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner5} />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </section>
      <Enquiryform
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <p style={{display:"none"}}>
        
Welcome to the premier Wedding Decorator Company in Malad, where dreams become reality and every detail is crafted with utmost precision. Our dedicated team at the Wedding Decorator Company in Malad is committed to transforming your special day into an enchanting and memorable experience.

As the leading Wedding Decorator Company in Malad, we take pride in our ability to create stunning and bespoke decorations that reflect your unique style and vision. Whether you envision a classic, elegant affair or a trendy, modern celebration, our team is here to bring your ideas to life.

At the Wedding Decorator Company in Malad, we understand that every wedding is unique, and we tailor our services to meet your specific needs. Our skilled decorators are well-versed in the latest trends and timeless classics, ensuring that your wedding decor is not only beautiful but also timeless.

With a passion for creativity and a commitment to excellence, the Wedding Decorator Company in Malad is your partner in turning your wedding day dreams into reality. From exquisite floral arrangements to elegant drapery and everything in between, our team has the expertise to create a magical ambiance that will leave a lasting impression on you and your guests.

Choose the Wedding Decorator Company in Malad for an unparalleled experience in creating breathtaking wedding decor. Our attention to detail, artistic flair, and dedication to customer satisfaction set us apart as the go-to choice for couples seeking a truly extraordinary celebration.

For a wedding that is as unique as your love story, trust the Wedding Decorator Company in Malad to bring your vision to life. Contact us today and let the journey to your dream wedding begin. Wedding Decorator Company in Malad – where every moment is a masterpiece! Wedding Decorator Company in Malad, your partner in creating memories that last a lifetime.
      </p>
      <Footer />
    </div>
  );
};

export default Decorebyevent;
