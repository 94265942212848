import banner1 from "../Asset/Banner/banner1.jpg";
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Enquiryform from "../Components/Enquiryform";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import comann from "../Asset/Banner/bannerdemo1.jpeg"
import p1 from "../Asset/img1/p1.jpg"
import p2 from "../Asset/img1/p2.jpg"
import p3 from "../Asset/img1/p3.jpg"
import p4 from "../Asset/img1/p4.jpg"
import { MetaTags } from 'react-meta-tags';
import p5 from "../Asset/img1/p5.jpg"
import p6 from "../Asset/img1/p6.jpg"
import p7 from "../Asset/img1/p7.jpg"
import banner2 from "../Asset/Banner/banner2.jpg";
import banner3 from "../Asset/Banner/banner3.jpg";
import banner4 from "../Asset/Banner/banner4.jpg";
import banner5 from "../Asset/Banner/banner5.jpg";
import ban1 from "../Asset/Banner/ban1.jpeg"
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import Footer from "../Components/Footer";
const Decorebytheme = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
       <MetaTags>
        <title>Wedding Theme Decorator in Malad | Celebrity Decorator</title>
        <meta title=" Wedding Theme Decorator in Malad | Celebrity Decorator" />
        <meta name="description" content="Elevate your celebration with our expert Wedding Theme Decorator in Malad. From imaginative concepts to meticulous execution, we bring your dreams to life." />
        <meta name="keywords" content="top-wedding-planner-decorator-malad,wedding-services-company-malad,destination-wedding-karjat,destination-wedding-company-malad,wedding-decorator-company-malad,wedding-theme-decorator-malad,wedding-venues-mumbai,best-wedding-bartenders-mumbai,best-wedding-caterers-mumbai,makeup-artists-wedding-mumbai,mehendi-artists-wedding-mumbai,wedding-invitation-cards-mumbai,best-wedding-photographers-videographers-mumbai,wedding-reception-bands-mumbai,comedians-weddings-mumbai,best-djs-weddings-mumbai,wedding-choreographer-hire-mumbai,wedding-host-mumbai,wedding-singers-mumbai,wedding-event-planner-mumbai" />
        <link rel="canonical" href="https://celebritydecorator.com/wedding-theme-decorator-malad" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Wedding Theme Decorator in Malad | Celebrity Decorator" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://celebritydecorator.com/" />
        <meta property="og:description" content="Elevate your celebration with our expert Wedding Theme Decorator in Malad. From imaginative concepts to meticulous execution, we bring your dreams to life." />
        <meta property="og:image" content="https://skworld.in/static/media/logo.0a02f996c9ddeb40558c.png" />
      </MetaTags>
      <section
        id="breadcrumb-section"
        className="breadcrumb-section clearfix margintop"
      >
        <div
          className="jarallax"
          style={{
            backgroundImage: "none",
            zIndex: 0,
            backgroundImage: `url(${comann})`,
          }}
        >
          <div className="overlay-black">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* breadcrumb-title - start */}
                  <div className="breadcrumb-title text-center mb-50">
                    <span className="sub-title">Celebrity Decorator</span>
                    <h2 className="big-title">
                      <strong>DECORE BY THEME</strong> 
                    </h2>
                  </div>
                  {/* breadcrumb-title - end */}
                  {/* breadcrumb-list - start */}
                  <div className="breadcrumb-list">
                    <ul>
                      <li className="breadcrumb-item">
                        <a href="index-1.html" className="breadcrumb-link">
                          Home
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        about us
                      </li>
                    </ul>
                  </div>
                  {/* breadcrumb-list - end */}
                </div>
              </div>
            </div>
          </div>
          <div
            id="jarallax-container-0"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              overflow: "hidden",
              pointerEvents: "none",
              zIndex: -100,
            }}
          >
            <div
              style={{
                backgroundPosition: "50% 50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${banner1})`,
                position: "fixed",
                top: 0,
                left: 0,

                height: "553.66px",
                overflow: "hidden",
                pointerEvents: "none",
                marginTop: "49.17px",
              }}
            />
          </div>
        </div>
      </section>

      <section
        id="service-section"
        className="service-section sec-ptb-100 clearfix"
      >
        <div className="container">
          <div className="service-wrapper">
            <div className="service-item clearfix">
              <div className="row">
                <div className="col-md-7">
                  {" "}
                  <div className="  float-left">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      loop={true}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner1} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner2} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner3} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner4} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner5} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      loop={true}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner5} />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
                <div className="col-md-5">
                  {" "}
                  <div className="service-content float-right">
                    <div className="service-title mb-30">
                      <h2 className="title-text">
                        <strong className="colorwhite fontstyle">Glam Night</strong>
                      </h2>
                    </div>
                    <p
                      className="service-description black-color mb-30 colorwhite"
                      style={{ textAlign: "justify" }}
                    >
                     
Transform your wedding into a glamorous night to remember with the expertise of the finest Wedding Theme Decorator in Malad. Elevate your celebration with exquisite decor that mirrors your unique style and vision. Our skilled team brings dreams to life, crafting a magical atmosphere that captivates guests from the moment they arrive. From enchanting centerpieces to dazzling lighting, every detail is meticulously curated to enhance the beauty of your special day. Immerse yourself in luxury and sophistication, creating an ambiance that resonates with the glamour you've always envisioned. Trust our Malad-based Wedding Theme Decorator to turn your wedding into a breathtaking spectacle, leaving a lasting impression on everyone in attendance.
                    
                    </p>

                    <div className="text-left">
                       <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                       enquiry now
                      </button>
                      <div className="modelpop" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-item clearfix">
              <div className="row">
                <div className="col-md-5 order-md-1 order-2">
                  <div className="service-content float-left">
                    <div className="service-title mb-30">
                      <h2 className="title-text">
                        <strong className="colorwhite fontstyle">Parisian Opera</strong>
                      </h2>
                    </div>
                    <p
                      className="service-description black-color mb-30 colorwhite"
                      style={{ textAlign: "justify" }}
                    >
                      
Transform your wedding into a mesmerizing spectacle with the enchanting touch of a Parisian Opera theme decorator in Malad. Elevate your celebration with timeless elegance and sophistication as our expert decorators bring the romance of Paris to your special day. Imagine ornate chandeliers, lavish drapes, and opulent floral arrangements setting the stage for an unforgettable union. Our team in Malad specializes in curating a dreamy ambiance that captures the essence of Parisian romance, ensuring every detail reflects your unique love story. Let us turn your wedding into a masterpiece, where love and the magic of Paris come together in a symphony of beauty and style.
                   
                    </p>

                    <div className="text-left">
                       <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                       enquiry now
                      </button>
                      <div className="modelpop" />
                    </div>
                  </div>
                </div>
                <div className="col-md-7 order-md-2 order-1">
                  <div className=" float-right">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      loop={true}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={p1} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner2} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner3} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner4} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner5} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      loop={true}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner5} />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-item clearfix">
              <div className="row">
                <div className="col-md-7">
                  {" "}
                  <div className="  float-left">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      loop={true}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={p2} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner2} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner3} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner4} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner5} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      loop={true}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner5} />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
                <div className="col-md-5">
                  {" "}
                  <div className="service-content float-right">
                    <div className="service-title mb-30">
                      <h2 className="title-text">
                        <strong className="colorwhite fontstyle">Sabhya </strong>
                      </h2>
                    </div>
                    <p
                      className="service-description black-color mb-30 colorwhite"
                      style={{ textAlign: "justify" }}
                    >
                    
Are you searching for an exquisite wedding theme decorator in Malad to transform your special day into a magical celebration? Look no further! Our team at Sabhya specializes in creating unforgettable wedding experiences through stunning and personalized decor. Nestled in the heart of Malad, we bring creativity and elegance to every event, ensuring a unique and enchanting ambiance. Whether you dream of a fairytale setting, rustic charm, or modern sophistication, our skilled decorators bring your vision to life. Trust Sabhya to curate the perfect atmosphere for your wedding, leaving you and your guests mesmerized by the beauty of your chosen theme.
                    
                    </p>

                    <div className="text-left">
                       <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                       enquiry now
                      </button>
                      <div className="modelpop" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-item clearfix">
              <div className="row">
                <div className="col-md-5 order-md-1 order-2">
                  <div className="service-content float-left">
                    <div className="service-title mb-30">
                      <h2 className="title-text">
                        <strong className="colorwhite fontstyle">
                          The Paisley Detail
                        </strong>
                      </h2>
                    </div>
                    <p
                      className="service-description black-color mb-30 colorwhite"
                      style={{ textAlign: "justify" }}
                    >
                     
Transform your wedding into a mesmerizing spectacle with the intricate charm of paisley details, elegantly curated by our expert team at Celebrity Decorator, the leading Wedding Theme Decorator in Malad. Elevate your celebration with the timeless allure of paisley patterns, seamlessly integrated into every aspect of your decor. From breathtaking centerpieces to exquisite table linens, our skilled decorators infuse the essence of romance and tradition. Trust Celebrity Decorator to weave the magic of paisley into your wedding, creating an ambiance that reflects your unique style. Let Celebrity Decorator be the canvas for your dream celebration, where paisley becomes the symbol of enduring love and sophistication.
                    
                    </p>

                    <div className="text-left">
                       <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                       enquiry now
                      </button>
                      <div className="modelpop" />
                    </div>
                  </div>
                </div>
                <div className="col-md-7 order-md-2 order-1">
                  <div className=" float-right">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      loop={true}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={p3} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner2} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner3} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner4} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner5} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      loop={true}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner5} />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-item clearfix">
              <div className="row">
                <div className="col-md-7">
                  {" "}
                  <div className="  float-left">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      loop={true}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={p4} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner2} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner3} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner4} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner5} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      loop={true}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner5} />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
                <div className="col-md-5">
                  {" "}
                  <div className="service-content float-right">
                    <div className="service-title mb-30">
                      <h2 className="title-text">
                        <strong className="colorwhite fontstyle">
                          The Royal Indian Affair
                        </strong>
                      </h2>
                    </div>
                    <p
                      className="service-description black-color mb-30 colorwhite"
                      style={{ textAlign: "justify" }}
                    >
                      For an enchanting royal Indian affair, entrust your dreams to the finest wedding theme decorator in Malad. Elevate your celebration with opulent decor that mirrors regal splendor, creating a majestic ambiance. Our meticulous attention to detail ensures every aspect reflects the grandeur of a royal union. From ornate floral arrangements to luxurious drapery, our skilled team transforms your venue into a palatial haven. Immerse your guests in the rich tapestry of Indian tradition, with vibrant colors and intricate designs adorning every corner. Let us weave a story of love and grandeur, making your wedding an affair to be remembered for a lifetime.
                    
                    </p>

                    <div className="text-left">
                       <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                       enquiry now
                      </button>
                      <div className="modelpop" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-item clearfix">
              <div className="row">
                <div className="col-md-5 order-md-1 order-2">
                  <div className="service-content float-left">
                    <div className="service-title mb-30">
                      <h2 className="title-text">
                        <strong className="colorwhite fontstyle">
                          Victorian Opulence
                        </strong>
                      </h2>
                    </div>
                    <p
                      className="service-description black-color mb-30 colorwhite"
                      style={{ textAlign: "justify" }}
                    >
                     

                     Transform your wedding into a regal affair with Victorian opulence, courtesy of the premier Wedding Theme Decorator in Malad. Immerse your celebration in the grandeur of the Victorian era, where ornate details, rich fabrics, and opulent florals reign supreme. Our expert decorators will weave a tapestry of sophistication, adorning your venue with intricate lace, vintage candelabras, and lush arrangements. Elevate your special day with the timeless charm of Victorian aesthetics, creating an atmosphere that exudes elegance and romance. Let Malad's top Wedding Theme Decorator turn your dreams into reality, ensuring every detail reflects the magnificence of a bygone era for an unforgettable celebration.
                    </p>

                    <div className="text-left">
                       <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                       enquiry now
                      </button>
                      <div className="modelpop" />
                    </div>
                  </div>
                </div>
                <div className="col-md-7 order-md-2 order-1">
                  <div className=" float-right">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      loop={true}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={p5} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner2} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner3} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner4} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner5} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      loop={true}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner5} />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-item clearfix">
              <div className="row">
                <div className="col-md-7">
                  {" "}
                  <div className="  float-left">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      loop={true}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={p6} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner2} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner3} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner4} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner5} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      loop={true}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner5} />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
                <div className="col-md-5">
                  {" "}
                  <div className="service-content float-right">
                    <div className="service-title mb-30">
                      <h2 className="title-text">
                        <strong className="colorwhite fontstyle">Zen Garden </strong>
                      </h2>
                    </div>
                    <p
                      className="service-description black-color mb-30 colorwhite"
                      style={{ textAlign: "justify" }}
                    >
                     
Transform your wedding dreams into reality with our exceptional wedding theme decorator services in Malad. Immerse yourself in the tranquility of a Zen Garden, where nature's beauty meets elegant decor. Our expert team specializes in creating a serene atmosphere, seamlessly blending traditional and contemporary elements. Picture a harmonious fusion of lush greenery, delicate flowers, and tasteful ornaments, setting the perfect backdrop for your special day. As the premier wedding theme decorator in Malad, we pay meticulous attention to every detail, ensuring a breathtaking and unforgettable celebration. Let us weave magic into your wedding, turning it into a masterpiece that reflects your love story and unique style.
                   
                    </p>

                    <div className="text-left">
                       <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                       enquiry now
                      </button>
                      <div className="modelpop" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-item clearfix">
              <div className="row">
                <div className="col-md-5 order-md-1 order-2">
                  <div className="service-content float-left">
                    <div className="service-title mb-30">
                      <h2 className="title-text">
                        <strong className="colorwhite fontstyle"  >Glam Night</strong>
                      </h2>
                    </div>
                    <p
                      className="service-description black-color mb-30 colorwhite"
                      style={{ textAlign: "justify" }}
                    >
                   Experience a night of glamour and enchantment with our exceptional wedding theme decorator services in Malad. Elevate your celebration with exquisite decor that reflects your unique style and vision. Our skilled team brings creativity to life, transforming your venue into a mesmerizing haven of elegance and romance. From stunning floral arrangements to captivating lighting, we curate every detail to perfection. Let us weave magic into your special day, ensuring it's an unforgettable affair. Trust us as your dedicated wedding theme decorator in Malad, where dreams come alive, and love is celebrated in the most enchanting way. Embrace the allure of a glamorous night that resonates with your personal style
                    </p>

                    <div className="text-left">
                       <button
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        onClick={() => setModalShow(true)}
                      >
                       enquiry now
                      </button>
                      <div className="modelpop" />
                    </div>
                  </div>
                </div>
                <div className="col-md-7 order-md-2 order-1">
                  <div className=" float-right">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                      }}
                      loop={true}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={p7} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner2} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner3} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner4} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionImg">
                          <img src={banner5} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      loop={true}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="decortionShortImg">
                          <img src={banner5} />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Enquiryform
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <p style={{display:"none"}}>
        
Looking for a Wedding Theme Decorator in Malad to turn your special day into a dream come true? Look no further! Our expert team of Wedding Theme Decorators in Malad is dedicated to transforming your wedding venue into a mesmerizing and unforgettable space.

At Wedding Theme Decorator in Malad, we understand the significance of every detail in creating a perfect ambiance for your celebration. Our skilled professionals specialize in bringing your vision to life, ensuring that every element complements your unique style and preferences.

As your preferred Wedding Theme Decorator in Malad, we pride ourselves on our creativity, attention to detail, and commitment to excellence. Whether you envision a classic, romantic, modern, or themed wedding, our team will work closely with you to tailor the decor to match your vision and create a truly magical atmosphere.

Our Wedding Theme Decorator services in Malad include a wide range of options, from elegant floral arrangements to stunning lighting designs, exquisite table settings, and more. We understand that each couple is unique, and we strive to reflect your personalities and preferences in every aspect of the decor.

When you choose Wedding Theme Decorator in Malad, you're not just getting a decorator – you're getting a partner in making your wedding day extraordinary. Our passion for creating beautiful and memorable experiences sets us apart, and we are dedicated to ensuring that your wedding decor surpasses all expectations.

Contact Wedding Theme Decorator in Malad today to discuss your ideas, explore our portfolio, and let us start planning the perfect decor for your special day. With Wedding Theme Decorator in Malad, your dream wedding is just a consultation away. Wedding Theme Decorator in Malad is your trusted partner for turning your wedding into a truly enchanting and magical celebration.
      </p>
      <Footer />
    </div>
  );
};

export default Decorebytheme;
