import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import Logo from "../Asset/Logo.png";
import "../css/Hadder.css";
import Dropdown from "react-bootstrap/Dropdown";
const Hadder = () => {
  const [expanded, setExpanded] = useState(false);

  const handleNavbarToggle = () => {
    setExpanded(!expanded);
  };

  const closeNavbar = () => {
    if (expanded) {
      setExpanded(false);
    }
  };

  return (
    <div>
      <Navbar expand="lg" className="bg-body-tertiary"  expanded={expanded}>
        <Container>
          <Navbar.Brand href="#home">
            <Link to="/" onClick={closeNavbar}>
              <img style={{ width: "90px" }} className="logo1" src={Logo} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            onClick={handleNavbarToggle}
            aria-controls="basic-navbar-nav"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav >
              <Nav.Link href="#home">
                <Link
                  to='/wedding-services-company-malad'
                  style={{ color: "white" }}
                  onClick={closeNavbar}
                >
                  Wedding Services
                </Link>
              </Nav.Link>
              <NavDropdown
                className="black paddingrl dropdown-h over"
                title="Wedding Destinations"
                id="basic-nav-dropdown"
                style={{ color: "#702d72" }}
              >
                <NavDropdown.Item>
                  <Link
                    to='/destination-wedding-karjat'
                    style={{ color: "#702d72" }}
                    onClick={closeNavbar}
                  >
                    paramount resort
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                className="black paddingrl dropdown-h over"
                title="Decore"
                id="basic-nav-dropdown"
                // style={{ color: "white" }}
              >
                <NavDropdown.Item>
                  <Link
                    to='/destination-wedding-company-malad'
                    style={{ color: "#702d72" }}
                    onClick={closeNavbar}
                  >
                    Decor by Destination
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to='/wedding-decorator-company-malad'
                    style={{ color: "#702d72" }}
                    onClick={closeNavbar}
                  >
                    Decore by Event
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to='/wedding-theme-decorator-malad'
                    style={{ color: "#702d72" }}
                    onClick={closeNavbar}
                  >
                    Decore by Theme
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
              {/* Add similar onClick handlers for other links */}
              <Nav.Link>
                <Link
                  to='/wedding-venues-mumbai'
                  style={{ color: "white "}}
                  onClick={closeNavbar}
                >
                  Venues
                </Link>
              </Nav.Link>
              <NavDropdown
                className="black paddingrl dropdown-h over"
                title="Vendors"
                id="basic-nav-dropdown"
                style={{ color: "white" }}
              >
                <NavDropdown.Item>
                  <Link
                    to='/best-wedding-bartenders-mumbai '
                    style={{ color: "#702d72" }}
                    onClick={closeNavbar}
                  >
                    Bartenders
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to='/best-wedding-caterers-mumbai'
                    style={{ color: "#702d72" }}
                    onClick={closeNavbar}
                  >
                    Caterers
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to='/wedding-invitation-cards-mumbai'
                    style={{ color: "#702d72" }}
                    onClick={closeNavbar}
                  >
                    Invitation cards & Wedding Stationery
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to='/makeup-artists-wedding-mumbai'
                    style={{ color: "#702d72" }}
                    onClick={closeNavbar}
                  >
                    Makeup Artists
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to='/mehendi-artists-wedding-mumbai'
                    style={{ color: "#702d72" }}
                    onClick={closeNavbar}
                  >
                    Mahendi Artist
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to='/best-wedding-photographers-videographers-mumbai'
                    style={{ color: "#702d72" }}
                    onClick={closeNavbar}
                  >
                    Photographers & Video Graphics
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                className="black paddingrl dropdown-h over"
                title="Artist"
                id="basic-nav-dropdown"
                style={{ color: "white" }}
              >
                <NavDropdown.Item>
                  <Link
                    to='/wedding-reception-bands-mumbai'
                    style={{ color: "#702d72" }}
                    onClick={closeNavbar}
                  >
                    Bands
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to='/comedians-weddings-mumbai'
                    style={{ color: "#702d72" }}
                    onClick={closeNavbar}
                  >
                    Comedians
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to='/best-djs-weddings-mumbai '
                    style={{ color: "#702d72" }}
                    onClick={closeNavbar}
                  >
                    DJ'S
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to='/wedding-choreographer-hire-mumbai'
                    style={{ color: "#702d72" }}
                    onClick={closeNavbar}
                  >
                    Dance Troupes and Choreographers
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to='/wedding-host-mumbai'
                    style={{ color: "#702d72" }}
                    onClick={closeNavbar}
                  >
                    Emcees
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to='/swedding-singers-mumbai'
                    style={{ color: "#702d72" }}
                    onClick={closeNavbar}
                  >
                    Singers
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="#home">
                <Link
                  to="/BLOG"
                  style={{ color: "white" }}
                  onClick={closeNavbar}
                >
                  Blog
                </Link>
              </Nav.Link>
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <Link
                    to='/wedding-event-planner-mumbai'
                    style={{ color: "white" }}
                    onClick={closeNavbar}
                  >
                    About Us
                  </Link>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                    {" "}
                    <Link
                      to="/Galleryer"
                      style={{ color: "#702d72" }}
                      onClick={closeNavbar}
                    >
                      Gallery
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* <Nav.Link>
               
              <button className="dropdownShow">
                            <Link to='/Aboutus' style={{color:"black"}}   onClick={closeNavbar}>About Us</Link>
                                <div className="dorpdown"  id="style-1">
                                    
                                    <div className="dropdown_list">
                                        <Link to="/Galleryer" style={{color:"black"}}   onClick={closeNavbar}>Gallery</Link>
                                       
                                    </div>
                                </div>
                            </button>
                
              </Nav.Link> */}
              {/* Add similar onClick handlers for other links */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Hadder;
