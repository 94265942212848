import React from 'react'
import banner1 from "../Asset/Banner/banner1.jpg"
import "../css/blog.css"
import comann from "../Asset/Banner/bannerdemo1.jpeg"

const Blogs = () => {
  return (
    <div>
           <section id="breadcrumb-section" className="breadcrumb-section clearfix margintop">
<div
className="jarallax"
style={{ backgroundImage: "none", zIndex: 0 , backgroundImage: `url(${comann})` , }}

>
<div className="overlay-black">
<div className="container">
 <div className="row justify-content-center">
   <div className="col-lg-6 col-md-12 col-sm-12">
     {/* breadcrumb-title - start */}
     <div className="breadcrumb-title text-center mb-50">
       <span className="sub-title">Celebrity Decorator</span>
       <h2 className="big-title">
         <strong>Blog </strong> 
       </h2>
     </div>
     {/* breadcrumb-title - end */}
     {/* breadcrumb-list - start */}
     <div className="breadcrumb-list">
       <ul>
         <li className="breadcrumb-item">
           <a href="index-1.html" className="breadcrumb-link">
             Home
           </a>
         </li>
         <li className="breadcrumb-item active" aria-current="page">
           Blog
         </li>
       </ul>
     </div>
     {/* breadcrumb-list - end */}
   </div>
 </div>
</div>
</div>
<div
id="jarallax-container-0"
style={{
 position: "absolute",
 top: 0,
 left: 0,
 width: "100%",
 height: "100%",
 overflow: "hidden",
 pointerEvents: "none",
 zIndex: -100
}}
>
<div
 style={{
   backgroundPosition: "50% 50%",
   backgroundSize: "cover",
   backgroundRepeat: "no-repeat",
   backgroundImage: `url(${banner1})` ,
   position: "fixed",
   top: 0,
   left: 0,

   height: "553.66px",
   overflow: "hidden",
   pointerEvents: "none",
   marginTop: "49.17px",
  
 }}
/>
</div>
</div>
</section>
<section id="facilitiesAmenities" className="pt-14 pb-10">
  <div className="container">
    {/* Heading */}
    {/* <div
      className="mb-10 text-center"
      data-cue="fadeIn"
      data-show="true"
      style={{
        animationName: "fadeIn",
        animationDuration: "1000ms",
        animationTimingFunction: "ease",
        animationDelay: "0ms",
        animationDirection: "normal",
        animationFillMode: "both"
      }}
    >
      <h2 className=" ff-heading mb-2">Testimonial</h2>
      <p className="h6 fw-medium text-body-secondary ff-sub text-uppercase ls-2">
        An quis eligendi atomorum latine
      </p>
    </div> */}
    <div className="row">
  <div className="col-md-4 col-sm-6 mt-10">
    <div
      className="single_blog_widget wow fadeInUp"
      data-wow-duration="1s"
      style={{
        visibility: "visible",
        animationDuration: "1s",
        animationName: "fadeInUp"
      }}
    >
      <div className="single_blog_img">
      <img src="https://images.pexels.com/photos/169194/pexels-photo-169194.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
        <div className="author_info">
          <div
            className="author_img bg-center bg-cover"
            style={{ backgroundImage: "url(./assets/img/home_01/man.png)" }}
          />
        
        </div>
      </div>
      <div className="content ">
        <div className="blog_date row">
          <a href="#" style={{paddingLeft:"15px"}}>
             wedding event 
          </a>
          {/* <a href="#" className='col-md-6'>
            <i className="fas fa-calendar-alt" /> 26 March, 2022
          </a> */}
        </div>
        <h4>Where Glamour Meets Perfection.</h4>
       
      </div>
    </div>
  </div>
  <div className="col-md-4 col-sm-6 mt-10">
    <div
      className="single_blog_widget wow fadeInUp"
      data-wow-duration="1.3s"
      style={{
        visibility: "visible",
        animationDuration: "1.3s",
        animationName: "fadeInUp"
      }}
    >
      <div className="single_blog_img">
      <img src="https://www.bambooevents.co.in/images/new/wedding7.webp" alt="" />
        <div className="author_info">
          <div
            className="author_img bg-center bg-cover"
            style={{ backgroundImage: "url(./assets/img/home_01/man_02.png)" }}
          />
        
        </div>
      </div>
      <div className="content">
      <div className="blog_date row">
          <a href="#" style={{paddingLeft:"15px"}} >
             wedding event 
          </a>
          {/* <a href="#" className='col-md-6'>
            <i className="fas fa-calendar-alt" /> 26 March, 2022
          </a> */}
        </div>
        <h4>Your Star-Crafted Events Await.</h4>
       
      </div>
    </div>
  </div>
  <div className="col-md-4 col-sm-6 mt-10">
    <div
      className="single_blog_widget wow fadeInUp"
      data-wow-duration="1.7s"
      style={{
        visibility: "visible",
        animationDuration: "1.7s",
        animationName: "fadeInUp"
      }}
    >
      <div className="single_blog_img">
      <img src="https://cdn0.weddingwire.in/vendor/9588/3_2/960/jpeg/wedding-decorators-royal-events-and-wedding-planner-stage-decor-3_15_419588-167938172975239.jpeg" alt="" />
        <div className="author_info">
          <div
            className="author_img bg-center bg-cover"
            style={{ backgroundImage: "url(./assets/img/home_01/man.png)" }}
          />
        
        </div>
      </div>
      <div className="content">
      <div className="blog_date row">
          <a href="#" style={{paddingLeft:"15px"}} >
             wedding event 
          </a>
          {/* <a href="#" className='col-md-6'>
            <i className="fas fa-calendar-alt" /> 26 March, 2022
          </a> */}
        </div>
        <h4> Unleashing Luxury in Every Detail.</h4>
       
      </div>
    </div>
  </div>
  <div className="col-md-4 col-sm-6 mt-10">
    <div
      className="single_blog_widget wow fadeInUp"
      data-wow-duration="1s"
      style={{
        visibility: "visible",
        animationDuration: "1s",
        animationName: "fadeInUp"
      }}
    >
      <div className="single_blog_img">
      <img src="https://theblessings.co.in/wp-content/uploads/2022/01/wedding-event.jpeg" alt="" />
        <div className="author_info">
          <div
            className="author_img bg-center bg-cover"
            style={{ backgroundImage: "url(./assets/img/home_01/man.png)" }}
          />
        
        </div>
      </div>
      <div className="content">
      <div className="blog_date row">
          <a href="#" style={{paddingLeft:"15px"}}>
             wedding event 
          </a>
          {/* <a href="#" className='col-md-6'>
            <i className="fas fa-calendar-alt" /> 26 March, 2022
          </a> */}
        </div>
        <h4>Unveiling the Celebrity Touch.</h4>
       
      </div>
    </div>
  </div>
  <div className="col-md-4 col-sm-6 mt-10">
    <div
      className="single_blog_widget wow fadeInUp"
      data-wow-duration="1.3s"
      style={{
        visibility: "visible",
        animationDuration: "1.3s",
        animationName: "fadeInUp"
      }}
    >
      <div className="single_blog_img">
      <img src="https://venuehub.pk/wp-content/uploads/IMG_20220608_161137_923-scaled.jpg" alt="" />
        <div className="author_info">
          <div
            className="author_img bg-center bg-cover"
            style={{ backgroundImage: "url(./assets/img/home_01/man_02.png)" }}
          />
        
        </div>
      </div>
      <div className="content">
      <div className="blog_date row">
          <a href="#" style={{paddingLeft:"15px"}}>
             wedding event 
          </a>
          {/* <a href="#" className='col-md-6'>
            <i className="fas fa-calendar-alt" /> 26 March, 2022
          </a> */}
        </div>
        <h4> Your VIP Pass to Unforgettable Events.</h4>
       
      </div>
    </div>
  </div>
  <div className="col-md-4 col-sm-6 mt-10">
    <div
      className="single_blog_widget wow fadeInUp"
      data-wow-duration="1.7s"
      style={{
        visibility: "visible",
        animationDuration: "1.7s",
        animationName: "fadeInUp"
      }}
    >
      <div className="single_blog_img">
      <img src={banner1} alt="" />
        <div className="author_info">
          <div
            className="author_img bg-center bg-cover"
            style={{ backgroundImage: "url(./assets/img/home_01/man.png)" }}
          />
        
        </div>
      </div>
      <div className="content">
      <div className="blog_date row">
          <a href="#"  style={{paddingLeft:"15px"}}>
             wedding event 
          </a>
          {/* <a href="#" className='col-md-6'>
            <i className="fas fa-calendar-alt" /> 26 March, 2022
          </a> */}
        </div>
        <h4>Celebrity-Infused Events, Unparalleled Excellence.</h4>
       
      </div>
    </div>
  </div>
  
</div>

  </div>
</section>
    </div>
  )
}

export default Blogs
