import React from 'react'
import comann from "../Asset/Banner/bannerdemo1.jpeg"
import banner1 from "../Asset/Banner/banner1.jpg"
import Footer from '../Components/Footer'
import { FaLocationDot } from 'react-icons/fa6';
import Enquiryform from '../Components/Enquiryform';
import { MetaTags } from 'react-meta-tags';
const Photographers= () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
          <MetaTags>
        <title>Best Wedding Photographers and Videographers In Mumbai  | Celebrity Decorator| Celebrity Decorator</title>
        <meta title=" Best Wedding Photographers and Videographers In Mumbai  | Celebrity Decorator| Celebrity Decorator" />
        <meta name="description" content=" Preserve the precious moments of your big day with the Best Wedding Photographers and Videographers in Mumbai. " />
        <meta name="keywords" content="top-wedding-planner-decorator-malad,wedding-services-company-malad,destination-wedding-karjat,destination-wedding-company-malad,wedding-decorator-company-malad,wedding-theme-decorator-malad,wedding-venues-mumbai,best-wedding-bartenders-mumbai,best-wedding-caterers-mumbai,makeup-artists-wedding-mumbai,mehendi-artists-wedding-mumbai,wedding-invitation-cards-mumbai,best-wedding-photographers-videographers-mumbai,wedding-reception-bands-mumbai,comedians-weddings-mumbai,best-djs-weddings-mumbai,wedding-choreographer-hire-mumbai,wedding-host-mumbai,wedding-singers-mumbai,wedding-event-planner-mumbai" />
        <link rel="canonical" href="https://celebritydecorator.com/best-wedding-photographers-videographers-mumbai" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Best Wedding Photographers and Videographers In Mumbai  | Celebrity Decorator| Celebrity Decorator" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://celebritydecorator.com/" />
        <meta property="og:description" content=" Preserve the precious moments of your big day with the Best Wedding Photographers and Videographers in Mumbai. " />
        <meta property="og:image" content="https://skworld.in/static/media/logo.0a02f996c9ddeb40558c.png" />
      </MetaTags>
       <div>
           <section id="breadcrumb-section" className="breadcrumb-section clearfix margintop">
  <div
    className="jarallax"
    style={{ backgroundImage: "none", zIndex: 0 , backgroundImage: `url(${comann})` , }}
    
  >
    <div className="overlay-black">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-sm-12">
            {/* breadcrumb-title - start */}
            <div className="breadcrumb-title text-center mb-50">
              <span className="sub-title">Celebrity Decorator</span>
              <h2 className="big-title">
                <strong>Photographers & Video Graphers</strong> 
              </h2>
            </div>
            {/* breadcrumb-title - end */}
            {/* breadcrumb-list - start */}
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-item">
                  <a href="index-1.html" className="breadcrumb-link">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  about us
                </li>
              </ul>
            </div>
            {/* breadcrumb-list - end */}
          </div>
        </div>
      </div>
    </div>
    <div
      id="jarallax-container-0"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        pointerEvents: "none",
        zIndex: -100
      }}
    >
      <div
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${banner1})` ,
          position: "fixed",
          top: 0,
          left: 0,
  
          height: "553.66px",
          overflow: "hidden",
          pointerEvents: "none",
          marginTop: "49.17px",
         
        }}
      />
    </div>
  </div>
</section>
<div >
   
    <div className="grid container">
  <div className="row">
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/vendorThumbnailImage/5bbc57d1c1707__Arshaan-Gandhi-Wedding-Photographer.jpg" alt="Snowy Mountains" />
            <div className="card__content">

              <h3 className="card__header" style={{margin:"0px"}}>Arshaan Gandhi</h3>
            <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/vendorThumbnailImage/5bbdd98cf421f__35mm-Candids-Wedding-Photography.jpg" alt="Snowy Mountains" />
            <div className="card__content">
              <h3 className="card__header" style={{margin:"0px"}}>35mm Candids</h3>
            <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    
  
  </div>
  
        
      </div>
    <Enquiryform
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
</div>


<p style={{display:"none"}}>
  
Capturing the essence of a wedding day requires skill, creativity, and an eye for detail. In the bustling city of Mumbai, where every moment is vibrant and full of life, finding the right wedding photographers and videographers is crucial to preserving those cherished memories.

Mumbai boasts a plethora of talented professionals who specialize in documenting weddings, offering couples a diverse range of options to choose from. These skilled individuals understand the significance of key moments during a wedding, ensuring that every smile, glance, and emotion is captured with precision.

The best wedding photographers and videographers in Mumbai e| Celebrity Decorator| Celebrity Decoratorxcel in storytelling through their lens, weaving together a narrative that reflects the unique love story of each couple. Their ability to capture candid moments, coupled with an understanding of lighting and composition, sets them apart in this competitive industry.

Whether it's a traditional ceremony or a modern celebration, these professionals have the expertise to adapt their style to suit the preferences of the couple. From pre-wedding shoots to the grand finale, they are adept at using their skills to create a visual masterpiece that will be cherished for a lifetime.

In a city known for its diversity, Mumbai's wedding photographers and videographers understand the importance of cultural nuances. They skillfully incorporate these elements into their work, adding an extra layer of depth and meaning to the visual narrative.

For couples seeking top-notch wedding documentation in Mumbai, these talented individuals are dedicated to providing a seamless and memorable experience. Their commitment to excellence, coupled with their passion for storytelling, makes them the ideal choice for those who want their wedding memories to be transformed into timeless works of art.
</p>
<Footer/>

    </div>
    </div>
  )
}

export default Photographers
