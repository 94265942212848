import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../css/new.css";
import img1 from "../Asset/img1/p1.jpg";
import imp2 from "../Asset/img1/p2.jpg";
import imp3 from "../Asset/img1/p3.jpg";
import imp4 from "../Asset/img1/p4.jpg";
import { Link } from "react-router-dom";
import { Autoplay } from "swiper/modules";
// import required modules

import Enquiryform from "./Enquiryform";
export default function Service() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1000: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="mb-5 tns-item tns-slide-active" id="tns1-item0">
            <div className="card card-overlay-slide text-bg-dark border-0">
              <img src={img1} srcSet={img1} className="card-img" alt="" />
              <div className="card-img-overlay">
                <span className="d-block mb-2"></span>
                <h5
                  className="card-title mb-4 h4 ff-sub text-uppercase fw-semibold  textcolor"
                  style={{ color: "white  !important" }}
                >
                  VENUE BOOKINGS
                </h5>
                <p className="textcolor" style={{ fontSize: "15px" }}>
                  VENUE BOOKINGS
                </p>
                <div className="card-link d-flex align-items-center">
                  <button
                    role="button"
                    tabIndex={0}
                    href="#!"
                    className="custom-btn "
                    onClick={() => setModalShow(true)}
                  >
                    enquiry now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div className="mb-5 tns-item tns-slide-active" id="tns1-item0">
            <div className="card card-overlay-slide text-bg-dark border-0">
              <img src={imp2} srcSet={imp2} className="card-img" alt="" />
              <div className="card-img-overlay">
                <span className="d-block mb-2"></span>
                <h4
                  className="card-title mb-4 h4 ff-sub text-uppercase fw-semibold  textcolor"
                  style={{ color: "white  !important" }}
                >
                  DESIGN AND CONCEPT
                </h4>
                <p className="textcolor" style={{ fontSize: "15px" }}>
                  DESIGN AND CONCEPT
                </p>
                <div className="card-link d-flex align-items-center">
                  <button
                    role="button"
                    tabIndex={0}
                    href="#!"
                    className="custom-btn "
                    onClick={() => setModalShow(true)}
                  >
                    enquiry now
                  </button>
                  <Enquiryform show={modalShow} onHide={() => setModalShow(false)} />
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div className="mb-5 tns-item tns-slide-active" id="tns1-item0">
            <div className="card card-overlay-slide text-bg-dark border-0">
              <img src={imp3} srcSet={imp3} className="card-img" alt="" />
              <div className="card-img-overlay">
                <span className="d-block mb-2"></span>
                <h4
                  className="card-title mb-4 h4 ff-sub text-uppercase fw-semibold  textcolor"
                  style={{ color: "white !important" }}
                >
                  PLANNING AND COORDINATION
                </h4>
                <p className="textcolor" style={{ fontSize: "15px" }}>
                  {" "}
                  PLANNING AND COORDINATION
                </p>
                <div className="card-link d-flex align-items-center">
                  <button
                    role="button"
                    tabIndex={0}
                    className="custom-btn "
                    onClick={() => setModalShow(true)}
                  >
                    enquiry now
                  </button>
                  <Enquiryform show={modalShow} onHide={() => setModalShow(false)} />
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div className="mb-5 tns-item tns-slide-active" id="tns1-item0">
            <div className="card card-overlay-slide text-bg-dark border-0">
              <img src={imp4} srcSet={imp4} className="card-img" alt="" />
              <div className="card-img-overlay">
                <span className="d-block mb-2"></span>
                <h4
                  className="card-title mb-4 h4 ff-sub text-uppercase fw-semibold  textcolor"
                  style={{ color: "white" }}
                >
                  ENTERTAINMENT
                </h4>
                <p className="textcolor" style={{ fontSize: "15px" }}>
                  ENTERTAINMENT
                </p>
                <div className="card-link d-flex align-items-center">
                  <button
                    role="button"
                    tabIndex={0}
                    className="custom-btn "
                    onClick={() => setModalShow(true)}
                  >
                    enquiry now
                  </button>
                  <Enquiryform show={modalShow} onHide={() => setModalShow(false)} />
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
