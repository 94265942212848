  import React from 'react'
import banner1 from "../Asset/Banner/banner1.jpg"
import Footer from '../Components/Footer'
import Testimonial2 from '../Components/Testimonial2'
import Awords from '../Components/Awords'
import i1 from "../Asset/icon/i1.png"
import i2 from "../Asset/icon/i2.png"
import i3 from "../Asset/icon/i3.png"
import i4 from "../Asset/icon/i4.png"
import i5 from "../Asset/icon/i5.png"
import i6 from "../Asset/icon/i6.png"
import i7 from "../Asset/icon/i7.png"
import i8 from "../Asset/icon/i8.png"
import img1 from "../Asset/img1/n1.jpg"
import img2 from "../Asset/img1/n2.jpg"
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade'; 
import Flip from 'react-reveal/Flip';
import "../css/staper.css"
import "../css/new.css"
import cost from "../Asset/icon/cost.png"
import quility from "../Asset/icon/qulity.png"
import rating from "../Asset/icon/rating.png"
import rate from "../Asset/icon/rate.png"
import Team from '../Components/team'
import comann from "../Asset/Banner/bannerdemo1.jpeg"
import { MetaTags } from 'react-meta-tags';
const Aboutus = () => {
  return (
    <div>
          <MetaTags>
        <title> Wedding Event Planner In Mumbai | Celebrity Decorator </title>
        <meta title="  Wedding Event Planner In Mumbai | Celebrity Decorator " />
        <meta name="description" content=" Transform your wedding dreams into reality with the top Wedding Event Planner in Mumbai. Our expert team crafts every detail perfectly." />
        <meta name="keywords" content="top-wedding-planner-decorator-malad,wedding-services-company-malad,destination-wedding-karjat,destination-wedding-company-malad,wedding-decorator-company-malad,wedding-theme-decorator-malad,wedding-venues-mumbai,best-wedding-bartenders-mumbai,best-wedding-caterers-mumbai,makeup-artists-wedding-mumbai,mehendi-artists-wedding-mumbai,wedding-invitation-cards-mumbai,best-wedding-photographers-videographers-mumbai,wedding-reception-bands-mumbai,comedians-weddings-mumbai,best-djs-weddings-mumbai,wedding-choreographer-hire-mumbai,wedding-host-mumbai,wedding-singers-mumbai,wedding-event-planner-mumbai" />
        <link rel="canonical" href="https://celebritydecorator.com/wedding-event-planner-mumbai" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Wedding Event Planner In Mumbai | Celebrity Decorator " />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://celebritydecorator.com/" />
        <meta property="og:description" content=" Transform your wedding dreams into reality with the top Wedding Event Planner in Mumbai. Our expert team crafts every detail perfectly." />
        <meta property="og:image" content="https://skworld.in/static/media/logo.0a02f996c9ddeb40558c.png" />
      </MetaTags>
      <section id="breadcrumb-section" className="breadcrumb-section clearfix margintop">
  <div
    className="jarallax"
    style={{ backgroundImage: "none", zIndex: 0 , backgroundImage: `url(${comann})` ,backgroundSize:"100% 100%" }}
    
  >
    <div className="overlay-black">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-sm-12">
            {/* breadcrumb-title - start */}
            <div className="breadcrumb-title text-center mb-50">
              <span className="sub-title">all you need to know</span>
              <h2 className="big-title">
                <strong>About Us</strong> 
              </h2>
            </div>
            {/* breadcrumb-title - end */}
            {/* breadcrumb-list - start */}
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-item">
                  <a href="index-1.html" className="breadcrumb-link">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  about us
                </li>
              </ul>
            </div>
            {/* breadcrumb-list - end */}
          </div>
        </div>
      </div>
    </div>
    <div
      id="jarallax-container-0"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        pointerEvents: "none",
        zIndex: -100
      }}
    >
      <div
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${banner1})` ,
          position: "fixed",
          top: 0,
          left: 0,
  
          height: "553.66px",
          overflow: "hidden",
          pointerEvents: "none",
          marginTop: "49.17px",
         
        }}
      />
    </div>
  </div>
</section>
<section id="about" className="pt-14 pb-14">
  <div className="container">
    <div
      className="row align-items-start align-items-md-stretch"
      data-cues="fadeIn"
      data-disabled="true"
    >
      <div
        className="col-12 col-lg-6 order-1 order-lg-0"
        data-cue="fadeIn"
        data-show="true"
        style={{
          animationName: "fadeIn",
          animationDuration: "1000ms",
          animationTimingFunction: "ease",
          animationDelay: "0ms",
          animationDirection: "normal",
          animationFillMode: "both"
        }}
      >
        {/* Image */}
        <Fade left>
        <div className="row g-0">
          <div className="col-6 order-md-1 order-2">
            <figure className="mb-0 pe-3 pe-md-4 pe-lg-5">
              <img
                src={img1}
                srcSet={img1}
                className="img-fluid w-100 rounded shadow-sm"
                alt=""
              />
            </figure>
          </div>
          <div className="col-6 order-md-1 order-2">
            <figure className="mb-0 pt-9">
              <img
                src={img2}
                srcSet={img2}
                className="img-fluid w-100 rounded shadow-sm"
                alt=""
              />
            </figure>
          </div>
        </div>
        </Fade>
        {/* /Image */}
      </div>
      <Fade right>
      <div  className="col-12 col-lg-6 order-0 order-lg-1 order-md-2 order-1"
        data-cue="fadeIn"
        data-show="true"
        style={{
          animationName: "fadeIn",
          animationDuration: "1000ms",
          animationTimingFunction: "ease",
          animationDelay: "0ms",
          animationDirection: "normal",
          animationFillMode: "both"
        }}
      >
        {/* Description */}
        <div className="bg-white h-100 d-flex align-items-center">
          <div className="p-lg-5 mb-8">
            <span className="mb-2 fw-medium text-secondary ff-sub text-uppercase ls-1 d-block">
              Welcome to
            </span>
            <h2 className=" ff-heading">Celebrity Decorator</h2>
            <p className="mb-6">
              
Premier event management company specializing in celebrity decoration services, offering the best wedding planning and decorating services in Malad. Our dedicated team ensures flawless execution and attention to detail for every occasion. With a focus on perfection, we bring your dream celebration to life. As the top choice for celebrity decorators, we create unforgettable experiences that reflect sophistication and style. Trust us to turn your special day into a magical and memorable event. Choose us as your preferred wedding planner and decorator in Malad for an unparalleled blend of creativity and expertise in celebrity event decoration.
            </p>
            <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                      >
                       < Link to="/Aboutus" style={{color:"white"}}>Enquiry Now</Link>
                      </a>
          </div>
        </div>
        {/* /Description */}
      </div>
      </Fade>
    </div>
  </div>
</section>

<section
  id="service-section"
  className="service-section sec-ptb-100 bg-gray-light clearfix"
  style={{backgroundColor:"#f7f7f7"}}
>
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="section-title mb-50 sr-fade1">
          
          <h2 className=" ff-heading mb-2" style={{textAlign:"center"}}>Team</h2>
      <p className="h6 fw-medium text-body-secondary ff-sub text-uppercase ls-2 " style={{textAlign:"center"}}>
        An quis eligendi atomorum latine
      </p>
      <Team/>
        </div>
      </div>
      
    </div>
  
  </div>
</section>

<section
  id="service-section"
  className="service-section sec-ptb-100 bg-gray-light clearfix"
  style={{backgroundColor:"#f7f7f7"}}
>
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="section-title mb-50 sr-fade1">
          
          <h2 className=" ff-heading mb-2" style={{textAlign:"center"}}>Advantages</h2>
      <p className="h6 fw-medium text-body-secondary ff-sub text-uppercase ls-2 " style={{textAlign:"center"}}>
        An quis eligendi atomorum latine
      </p>
        </div>
      </div>
      
    </div>
    <div className="service-wrapper sr-fade1">
      <ul>
      <Flip left>
        <li>
          <a href="#!">
            <span className="icon">
              <img src={i1} style={{width:"55px"}}/>
            </span>
            <strong className="service-title">friendly team</strong>
            <small className="service-sub-title">More than 200 teams</small>
          </a>
        </li>
        </Flip>
        <Flip left>
        <li>
          <a href="#!">
            <span className="icon">
            <img src={i2} style={{width:"55px"}} />
            </span>
            <strong className="service-title">Unique Scenario</strong>
            <small className="service-sub-title">
              we thinking out of the box
            </small>
          </a>
        </li>
        </Flip>
        <Flip left>
        <li>
          <a href="#!">
            <span className="icon">
              <img src={i3} style={{width:"55px"}}/>
            </span>
            <strong className="service-title">perfect venues</strong>
            <small className="service-sub-title">perfect venues</small>
          </a>
        </li>
        </Flip>
        <Flip left>
        <li>
          <a href="#!">
            <span className="icon">
            <img src={i4} style={{width:"55px"}}/>
            </span>
            <strong className="service-title">24/7 hours support</strong>
            <small className="service-sub-title">anitime anywhere</small>
          </a>
        </li>
        </Flip>
        <Flip left>
        <li>
          <a href="#!">
            <span className="icon">
            <img src={i5} style={{width:"55px"}}/>
            </span>
            <strong className="service-title">Unforgetable Times</strong>
            <small className="service-sub-title">More than 200 teams</small>
          </a>
        </li>
        </Flip>
        <Flip left>
        <li>
          <a href="#!">
            <span className="icon">
            <img src={i6} style={{width:"55px"}}/>
            </span>
            <strong className="service-title">Briliant Idea</strong>
            <small className="service-sub-title">
              We thinking out of the box
            </small>
          </a>
        </li>
        </Flip>
        <Flip left>
        <li>
          <a href="#!">
            <span className="icon">
            <img src={i8} style={{width:"55px"}}/>
            </span>
            <strong className="service-title">Unique Scenario</strong>
            <small className="service-sub-title">perfact venues</small>
          </a>
        </li>
        </Flip>
        <Flip left>
        <li>
          <a href="#!">
            <span className="icon">
            <img src={i6} style={{width:"55px"}}/> 
            </span>
            <strong className="service-title">Best Management</strong>
            <small className="service-sub-title">anytime anywhere</small>
          </a>
        </li>
        </Flip>
      </ul>
    </div>
  </div>
</section>
<section className="ttm-row fid-section ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes bg-img11 clearfix">
  <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
  <div className="container">
    <div className="row ttm-fid-row-wrapper">
      <div className="col-md-3 col-sm-6 col-12">
        <div className="ttm-fid inside ttm-fid-view-lefticon style2">
          <div className="" style={{textAlign:"center"}}>
            <div className="ttm-fid-icon-wrapper ttm-textcolor-skincolor">
              <div>
              <i className="flaticon-haircut">
                <img src={rate}/>
              </i>
              </div>
            </div>
            <h4 className="ttm-fid-inner">
              <span
                data-appear-animation="animateDigits"
                data-from={0}
                data-to={15}
                data-interval={1}
                data-before=""
                data-before-style="sup"
                data-after=""
                data-after-style="sub"
                className="numinate completed"
              >
                15
              </span>
            </h4>
          </div>
          <h3 className="ttm-fid-title textcenterje" >Years Of Experience</h3>
          {/* <div className="ttm-fid-contents text-left">
            <h3 className="ttm-fid-title" >Years Of Experience</h3>
          </div> */}
        </div>
      </div>
      <div className="col-md-3 col-sm-6 col-12">
        <div className="ttm-fid inside ttm-fid-view-lefticon style2">
          <div className="" style={{textAlign:"center"}}>
            <div className="ttm-fid-icon-wrapper ttm-textcolor-skincolor">
              <div>
              <i className="flaticon-haircut">
                <img src={rating} />
              </i>
              </div>
            </div>
            <h4 className="ttm-fid-inner">
              <span
                data-appear-animation="animateDigits"
                data-from={0}
                data-to={320}
                data-interval={10}
                data-before=""
                data-before-style="sup"
                data-after=""
                data-after-style="sub"
                className="numinate completed"
              >
                320
              </span>
            </h4>
          </div>
          <h3 className="ttm-fid-title textcenterje" >Field Exper</h3>
          {/* <div className="ttm-fid-contents text-left">
            <h3 className="ttm-fid-title" >Field Expert</h3>
          </div> */}
        </div>
      </div>
      <div className="col-md-3 col-sm-6 col-12">
        <div className="ttm-fid inside ttm-fid-view-lefticon style2">
          <div className="" style={{textAlign:"center"}}>
            <div className="ttm-fid-icon-wrapper ttm-textcolor-skincolor">
              <div>
              <i className="flaticon-salon">
                <img src={quility}/>
              </i>
              </div>
            </div>
            <h4 className="ttm-fid-inner">
              <span
                data-appear-animation="animateDigits"
                data-from={0}
                data-to={45}
                data-interval={5}
                data-before=""
                data-before-style="sup"
                data-after=""
                data-after-style="sub"
                className="numinate completed"
              >
                45
              </span>
            </h4>
          </div>
          <h3 className="ttm-fid-title textcenterje">Co-operate</h3>
          {/* <div className="ttm-fid-contents text-left">
            <h3 className="ttm-fid-title" >Co-operate</h3>
          </div> */}
        </div>
      </div>
      <div className="col-md-3 col-sm-6 col-12">
        <div className="ttm-fid inside ttm-fid-view-lefticon style2">
          <div className="" style={{textAlign:"center"}}>
            <div className="ttm-fid-icon-wrapper ttm-textcolor-skincolor">
             <div>
              <i className="flaticon-barber">
                <img src={cost} />
              </i>
              </div>
            </div>
            <h4 className="ttm-fid-inner">
              <span
                data-appear-animation="animateDigits"
                data-from={0}
                data-to={2555}
                data-interval={100}
                data-before=""
                data-before-style="sup"
                data-after=""
                data-after-style="sub"
                className="numinate completed"
              >
                2555
              </span>
              
            </h4>
          </div><h3 className="ttm-fid-title textcenterje" >Customers Winner</h3>
          {/* <div className="ttm-fid-contents text-left">
            <h3 className="ttm-fid-title" >Customers Winner</h3>
          </div> */}
        </div>
      </div>
    </div>
    <div className="row">
      <div className="featured-icon-box icon-align-before-content style7 mt-50 ml-15 res-991-mt-30 res-767-mt-10">
        <div className="featured-icon">
          <div className="ttm-icon ttm-icon_element-color-skincolor ttm-bgcolor-white ttm-icon_element-style-rounded ttm-icon_element-size-sm">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth={0}
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              color='#a3227ecf'
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M436.9 364.8c-14.7-14.7-50-36.8-67.4-45.1-20.2-9.7-27.6-9.5-41.9.8-11.9 8.6-19.6 16.6-33.3 13.6-13.7-2.9-40.7-23.4-66.9-49.5-26.2-26.2-46.6-53.2-49.5-66.9-2.9-13.8 5.1-21.4 13.6-33.3 10.3-14.3 10.6-21.7.8-41.9C184 125 162 89.8 147.2 75.1c-14.7-14.7-18-11.5-26.1-8.6 0 0-12 4.8-23.9 12.7-14.7 9.8-22.9 18-28.7 30.3-5.7 12.3-12.3 35.2 21.3 95 27.1 48.3 53.7 84.9 93.2 124.3l.1.1.1.1c39.5 39.5 76 66.1 124.3 93.2 59.8 33.6 82.7 27 95 21.3 12.3-5.7 20.5-13.9 30.3-28.7 7.9-11.9 12.7-23.9 12.7-23.9 2.9-8.1 6.2-11.4-8.6-26.1z" />
            </svg>
          </div>
        </div>
        <div className="featured-content ">
          <div className="featured-title">
            <h3 className="ttm-textcolor-white " style={{color:"white"}}>
             <span style={{color:"white"}}>  First Step Call Lirena Now !</span>
            </h3>
          </div>
          <div className="featured-desc">
            <p>Phone : 9320060023</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section id="facilitiesAmenities" className="pt-14 pb-10">
  <div className="container">
    {/* Heading */}
    <div
      className="mb-10 text-center"
      data-cue="fadeIn"
      data-show="true"
      style={{
        animationName: "fadeIn",
        animationDuration: "1000ms",
        animationTimingFunction: "ease",
        animationDelay: "0ms",
        animationDirection: "normal",
        animationFillMode: "both"
      }}
    >
      <h2 className=" ff-heading mb-2">Testimonial</h2>
      <p className="h6 fw-medium text-body-secondary ff-sub text-uppercase ls-2">
        An quis eligendi atomorum latine
      </p>
    </div>
    {/* /Heading */}
    {/* List */}
    <Testimonial2/>
    {/* /List */}
  </div>
</section>
<section id="facilitiesAmenities" className="pt-14 pb-10" style={{backgroundColor:"whitesmoke"}}>
  <div className="container">
    {/* Heading */}
    <div
      className="mb-10 text-center"
      data-cue="fadeIn"
      data-show="true"
      style={{
        animationName: "fadeIn",
        animationDuration: "1000ms",
        animationTimingFunction: "ease",
        animationDelay: "0ms",
        animationDirection: "normal",
        animationFillMode: "both"
      }}
    >
      <h2 className=" ff-heading mb-2">Awards</h2>
      <p className="h6 fw-medium text-body-secondary ff-sub text-uppercase ls-2">
        An quis eligendi atomorum latine
      </p>
    </div>
    {/* /Heading */}
    {/* List */}
    <Awords/>
    {/* /List */}
  </div>
</section>
<p style={{display:"none"}}>
  
Planning a dream wedding involves meticulous attention to detail and a keen sense of creativity. In the bustling city of Mumbai, where the vibrant energy meets cultural richness, finding the perfect wedding event planner is crucial for turning your special day into an unforgettable celebration.

A skilled wedding event planner in Mumbai understands the significance of every nuance that contributes to a couple's unique love story. From conceptualization to execution, these professionals weave magic into each element, ensuring a seamless and joyous experience for the bride, groom, and their guests.

The expertise of a wedding event planner in Mumbai shines through in their ability to blend traditional customs with contemporary trends, creating a harmonious fusion that resonates with the diverse culture of the city. Whether it's selecting the ideal venue, curating stunning decor, managing logistics, or coordinating the timeline, these planners excel in making the entire process stress-free and enjoyable.

Mumbai's wedding event planners are adept at transforming your vision into reality. They leverage their local knowledge to scout picturesque locations, negotiate with vendors for the best deals, and add that touch of Mumbai glamour to your celebrations. The city's skyline becomes the backdrop to your love story, creating a cinematic experience that will be etched in your memory forever.

Collaborating with a wedding event planner in Mumbai means entrusting your dreams to seasoned professionals who understand the essence of your big day. Their attention to detail, creativity, and commitment to delivering a flawless event make them indispensable partners in the journey towards marital bliss.

In a city that never sleeps, your wedding deserves to stand out as a unique, joyous affair. Let a skilled wedding event planner in Mumbai craft the perfect narrative for your union, turning your wedding day into a cherished chapter in the vibrant tapestry of Mumbai's cultural landscape.






</p>
<Footer></Footer>

    </div>
  )
}

export default Aboutus
