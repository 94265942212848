import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import peramount1 from "../Asset/Banner/peramount.jpg"
import img1 from "../Asset/Banner/banner1.jpg"
import img2 from "../Asset/Banner/banner2.jpg"
import img3 from "../Asset/Banner/banner3.jpg"
import img4 from "../Asset/Banner/banner4.jpg"
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';



// import required modules
import { EffectCoverflow,Navigation} from 'swiper/modules';

export default function Peramount() {
  return (
    <>
    <div className='swiperer'>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
       
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
         
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow,Navigation]}
        className="mySwipersds"
      >
        <SwiperSlide>
          <img className='imageheiwei' src={peramount1} />
        </SwiperSlide>
        <SwiperSlide>
          <img className='imageheiwei' src={img1}/>
        </SwiperSlide>
        <SwiperSlide>
          <img className='imageheiwei' src={img2}/>
        </SwiperSlide>
        <SwiperSlide>
          <img className='imageheiwei' src={img3}/>
        </SwiperSlide>
        <SwiperSlide>
          <img className='imageheiwei ' src={img4}/>
        </SwiperSlide>
        
      </Swiper>
      </div>
    </>
  );
}
