import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import i1 from "../Asset/icon/i1.png"
import i2 from "../Asset/icon/i2.png"
import i3 from "../Asset/icon/i3.png"
import i4 from "../Asset/icon/i4.png"
import i5 from "../Asset/icon/i5.png"
import i6 from "../Asset/icon/i6.png"
import i7 from "../Asset/icon/i7.png"
import i8 from "../Asset/icon/i8.png"
import Peramount from "../Components/peramount";
// Import Swiper styles
import comann from "../Asset/Banner/bannerdemo1.jpeg"
import Form from 'react-bootstrap/Form';
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import Peram from "../Asset/Banner/peramount.jpg";
import banner1 from "../Asset/Banner/banner1.jpg";
import Button from "react-bootstrap/Button";
import Footer from "../Components/Footer";
import { MetaTags } from 'react-meta-tags';
const PeramountResort = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <div>
        <MetaTags>
        <title>Destination Wedding in Karjat | Celebrity Decorator</title>
        <meta title=" Destination Wedding in Karjat | Celebrity Decorator" />
        <meta name="description" content="Embark on a journey to love with a Destination Wedding in Karjat. Explore enchanting venues and create unforgettable moments amidst nature's beauty." />
        <meta name="keywords" content="top-wedding-planner-decorator-malad,wedding-services-company-malad,destination-wedding-karjat,destination-wedding-company-malad,wedding-decorator-company-malad,wedding-theme-decorator-malad,wedding-venues-mumbai,best-wedding-bartenders-mumbai,best-wedding-caterers-mumbai,makeup-artists-wedding-mumbai,mehendi-artists-wedding-mumbai,wedding-invitation-cards-mumbai,best-wedding-photographers-videographers-mumbai,wedding-reception-bands-mumbai,comedians-weddings-mumbai,best-djs-weddings-mumbai,wedding-choreographer-hire-mumbai,wedding-host-mumbai,wedding-singers-mumbai,wedding-event-planner-mumbai" />
        <link rel="canonical" href="https://celebritydecorator.com/destination-wedding-karjat" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Destination Wedding in Karjat | Celebrity Decorator" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://celebritydecorator.com/" />
        <meta property="og:description" content="Embark on a journey to love with a Destination Wedding in Karjat. Explore enchanting venues and create unforgettable moments amidst nature's beauty." />
        <meta property="og:image" content="https://skworld.in/static/media/logo.0a02f996c9ddeb40558c.png" />
      </MetaTags>
      <section
        id="breadcrumb-section"
        className="breadcrumb-section clearfix margintop"
      >
        <div
          className="jarallax"
          style={{
            backgroundImage: "none",
            zIndex: 0,
            backgroundSize:"cover",
            backgroundImage: `url(${comann})`,
          }}
        >
          <div className="overlay-black">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* breadcrumb-title - start */}
                  <div className="breadcrumb-title text-center mb-50">
                    <span className="sub-title">Celebrity Decorator</span>
                    <h2 className="big-title">
                      <strong>Paramount Resort</strong>
                    </h2>
                  </div>
                  {/* breadcrumb-title - end */}
                  {/* breadcrumb-list - start */}
                  <div className="breadcrumb-list">
                    <ul>
                      <li className="breadcrumb-item">
                        <a href="index-1.html" className="breadcrumb-link">
                          Home
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Paramount Resort
                      </li>
                    </ul>
                  </div>
                  {/* breadcrumb-list - end */}
                </div>
              </div>
            </div>
          </div>
          <div
            id="jarallax-container-0"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              overflow: "hidden",
              pointerEvents: "none",
              zIndex: -100,
            }}
          >
            <div
              style={{
                backgroundPosition: "50% 50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${banner1})`,
                position: "fixed",
                top: 0,
                left: 0,

                height: "553.66px",
                overflow: "hidden",
                pointerEvents: "none",
                marginTop: "49.17px",
              }}
            />
          </div>
        </div>
      </section>
      <div className="container">
        <div
          className="col-md-12"
          style={{marginTop:"20px" , padding: "20px", backgroundImage: `url(${Peram})` ,backgroundSize:"cover"}}
        >
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6 ">
            
            <div className="formpermount form-1 peramuntform" > 
            <h6 style={{textAlign:"center" ,padding:"10px"}}>Enquiry Now</h6>
            <Form >
    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label style={{color:"#ffbe30"}}>Name</Form.Label>
        <Form.Control type="text" placeholder="Full Name"  />
      </Form.Group>
      
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label style={{color:"#ffbe30"}}>Email address</Form.Label>
        <Form.Control type="email" placeholder="name@example.com"    />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label style={{color:"#ffbe30"}}>Mobile No</Form.Label>
        <Form.Control type="number" placeholder="123456789"  />
      </Form.Group>
      
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label style={{color:"#ffbe30"}}>Service</Form.Label>
        <Form.Control type="text" placeholder="service"  />
      </Form.Group>
    
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label style={{color:"#ffbe30"}}>Small Details</Form.Label>
        <Form.Control as="textarea"   rows={3} />
      </Form.Group>   
     
      <Button variant="primary"  type="submit" style={{backgroundColor:"#ffbe30",borderRadius:"10px"}}>
        Submit
      </Button>
    </Form>
            </div>
            </div>
          </div>
        </div>
        <div style={{padding:"10px" ,border:"0.5px solid #ffbe30",margin:"30px", borderRadius:"25px"} }>
        <p>Join us at Paramount Resort for a star-studded evening of glamour and entertainment. Experience the red carpet extravaganza with A-listers and influencers, showcasing dazzling performances and high-fashion statements. Be part of the glitz as we celebrate industry excellence at this exclusive soirée. At Paramount Resort, luxury meets entertainment for an unforgettable night. Don't miss this glamorous affair—it's an experience that promises to linger in your memory</p>
        </div>
        <section
  id="service-section"
  className="service-section sec-ptb-100 bg-gray-light clearfix"
  style={{backgroundColor:"#f7f7f7"}}
>
  <div className="container">
    <div className="row">
      <div className="col-lg-6">
        <div className="section-title mb-50 sr-fade1">
         
         
         
        </div>
      </div>
      
    </div>
    <div className="service-wrapper sr-fade1">
      <ul>
        <li>
          <a href="#!">
            <span className="icon">
              <img src={i1} style={{width:"55px"}}/>
            </span>
            <strong className="service-title">friendly team</strong>
          
          </a>
        </li>
        <li>
          <a href="#!">
            <span className="icon">
            <img src={i2} style={{width:"55px"}} />
            </span>
            <strong className="service-title">Unique Scenario</strong>
           
          </a>
        </li>
        <li>
          <a href="#!">
            <span className="icon">
              <img src={i3} style={{width:"55px"}}/>
            </span>
            <strong className="service-title">perfect venues</strong>
           
          </a>
        </li>
        <li>
          <a href="#!">
            <span className="icon">
            <img src={i4} style={{width:"55px"}}/>
            </span>
            <strong className="service-title">24/7 hours support</strong>
            
          </a>
        </li>
        <li>
          <a href="#!">
            <span className="icon">
            <img src={i5} style={{width:"55px"}}/>
            </span>
            <strong className="service-title">Unforgetable Times</strong>
           
          </a>
        </li>
        <li>
          <a href="#!">
            <span className="icon">
            <img src={i6} style={{width:"55px"}}/>
            </span>
            <strong className="service-title">Briliant Idea</strong>
           
          </a>
        </li>
        <li>
          <a href="#!">
            <span className="icon">
            <img src={i8} style={{width:"55px"}}/>
            </span>
            <strong className="service-title">Unique Scenario</strong>
           
          </a>
        </li>
        <li>
          <a href="#!">
            <span className="icon">
            <img src={i6} style={{width:"55px"}}/> 
            </span>
            <strong className="service-title">Best Management</strong>
           
          </a>
        </li>
      </ul>
    </div>
  </div>
</section>
<section id="facilitiesAmenities" className="pt-14 pb-10">
  <div className="container">
    {/* Heading */}
    <div
      className="mb-10 text-center"
      data-cue="fadeIn"
      data-show="true"
      style={{
        animationName: "fadeIn",
        animationDuration: "1000ms",
        animationTimingFunction: "ease",
        animationDelay: "0ms",
        animationDirection: "normal",
        animationFillMode: "both"
      }}
    >
      <h2 className="ff-heading mb-2">Gallery</h2>
      <p className="h6 fw-medium text-body-secondary ff-sub text-uppercase ls-2">
        An quis eligendi atomorum latine
      </p>
    </div>
    {/* /Heading */}
    {/* List */}
    <Peramount/>
    {/* /List */}
  </div>
</section>

        </div>
        <p style={{display:"none"}}>
          
Are you considering a destination wedding in Karjat? Karjat, known for its picturesque landscapes and serene ambiance, is an ideal location for couples seeking a unique and memorable wedding experience. A destination wedding in Karjat offers a perfect blend of natural beauty, tranquility, and cultural charm.

Imagine exchanging your vows amidst the lush greenery and scenic beauty that Karjat has to offer. The mesmerizing backdrop of hills, waterfalls, and sprawling fields creates a romantic atmosphere that will make your special day truly unforgettable. A destination wedding in Karjat allows you to escape the hustle and bustle of the city and immerse yourselves in the tranquility of nature.

Karjat provides a range of venues that cater to different preferences, from luxurious resorts to charming farmhouses. Whether you envision an intimate ceremony or a grand celebration, there's a perfect venue in Karjat to suit your style. The region's pleasant weather further adds to the allure, making it an ideal destination for weddings throughout the year.

Planning a destination wedding involves coordinating various elements, from accommodation to transportation and event logistics. Fortunately, Karjat offers a variety of services and experienced wedding planners who can assist in organizing every aspect of your celebration. This ensures a seamless and stress-free experience for you and your guests.

Karjat's unique combination of natural beauty and cultural richness provides ample opportunities for pre-wedding and post-wedding festivities. From exploring the scenic surroundings to indulging in local cuisine, your wedding celebrations in Karjat can be a delightful blend of tradition and modernity.

So, if you're dreaming of a destination wedding that stands out, consider Karjat as your chosen location. A Karjat destination wedding promises not only a beautiful ceremony but also a memorable experience for you, your partner, and your guests. Begin your journey into marital bliss surrounded by the charm and allure of Karjat – a destination where love and nature intertwine seamlessly. Karjat, the perfect backdrop for your perfect day. Karjat – where dreams become reality, and love blossoms amidst nature's embrace.
        </p>
        <Footer/>
       </div>
  
  );
};

export default PeramountResort;
