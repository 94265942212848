import React from 'react'
import banner1 from "../Asset/Banner/banner1.jpg"
import Footer from '../Components/Footer'
import { FaLocationDot } from 'react-icons/fa6';
import Enquiryform from '../Components/Enquiryform';
import comann from "../Asset/Banner/bannerdemo1.jpeg"
import { MetaTags } from 'react-meta-tags';
import "../css/cards.css"
const Venue = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
            <MetaTags>
        <title>Wedding Venues In Mumbai | Celebrity Decorator</title>
        <meta title=" Wedding Venues In Mumbai | Celebrity Decorator" />
        <meta name="description" content="Explore a curated selection of enchanting Wedding Venues in Mumbai. From luxurious ballrooms to scenic outdoor settings, find the perfect backdrop." />
        <meta name="keywords" content="top-wedding-planner-decorator-malad,wedding-services-company-malad,destination-wedding-karjat,destination-wedding-company-malad,wedding-decorator-company-malad,wedding-theme-decorator-malad,wedding-venues-mumbai,best-wedding-bartenders-mumbai,best-wedding-caterers-mumbai,makeup-artists-wedding-mumbai,mehendi-artists-wedding-mumbai,wedding-invitation-cards-mumbai,best-wedding-photographers-videographers-mumbai,wedding-reception-bands-mumbai,comedians-weddings-mumbai,best-djs-weddings-mumbai,wedding-choreographer-hire-mumbai,wedding-host-mumbai,wedding-singers-mumbai,wedding-event-planner-mumbai" />
        <link rel="canonical" href="https://celebritydecorator.com/wedding-venues-mumbai" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Wedding Venues In Mumbai | Celebrity Decorator" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://celebritydecorator.com/" />
        <meta property="og:description" content="Explore a curated selection of enchanting Wedding Venues in Mumbai. From luxurious ballrooms to scenic outdoor settings, find the perfect backdrop." />
        <meta property="og:image" content="https://skworld.in/static/media/logo.0a02f996c9ddeb40558c.png" />
      </MetaTags>
           <section id="breadcrumb-section" className="breadcrumb-section clearfix margintop">
  <div
    className="jarallax"
    style={{ backgroundImage: "none", zIndex: 0 , backgroundImage: `url(${comann})` , }}
    
  >
    <div className="overlay-black">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-sm-12">
            {/* breadcrumb-title - start */}
            <div className="breadcrumb-title text-center mb-50">
              <span className="sub-title">Celebrity Decorator</span>
              <h2 className="big-title">
                <strong>venue</strong> 
              </h2>
            </div>
            {/* breadcrumb-title - end */}
            {/* breadcrumb-list - start */}
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-item">
                  <a href="index-1.html" className="breadcrumb-link">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  about us
                </li>
              </ul>
            </div>
            {/* breadcrumb-list - end */}
          </div>
        </div>
      </div>
    </div>
    <div
      id="jarallax-container-0"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        pointerEvents: "none",
        zIndex: -100
      }}
    >
      <div
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${banner1})` ,
          position: "fixed",
          top: 0,
          left: 0,
  
          height: "553.66px",
          overflow: "hidden",
          pointerEvents: "none",
          marginTop: "49.17px",
         
        }}
      />
    </div>
  </div>
</section>
<div >
    
</div>


     
<div className="grid container" >
  <div className="row">
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/venueThumbnailImage/5d9f093b0d268__lemmeridienjaipurHotel3.jpg" alt="Snowy Mountains" />
            <div className="card__content">

              <h3 className="card__header" style={{margin:"0px"}}>Renaissance Mumbai</h3>
             <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/venueThumbnailImage/5ba1e0fca09b5__Wedding%20Venues%20in%20Jaipur.jpg" alt="Snowy Mountains" />
            <div className="card__content">
              <h3 className="card__header" style={{margin:"0px"}}>Holiday Inn Sakinaka</h3>
             <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/venueThumbnailImage/5d9efe20a509f__jw-marriott-mumbai-juhu-mumbai-swimming-pool-133711578405-jpeg-g.jpg" alt="Snowy Mountains" />
            <div className="card__content">
              <h3 className="card__header" style={{margin:"0px"}}>Pavte Banquet Park</h3>
             <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    
  </div>
  
        
      </div>
<p style={{display:"none"}}>

When it comes to planning your special day, selecting the perfect wedding venue is a crucial decision that sets the tone for the entire celebration. In the vibrant city of Mumbai, you are spoilt for choice with a plethora of stunning wedding venues that cater to a variety of tastes and preferences.

Mumbai, often referred to as the "City of Dreams," boasts an array of picturesque locations to host your wedding festivities. From opulent banquet halls to charming garden venues, Mumbai offers a diverse range of options that can turn your dream wedding into a reality.

One of the key factors that make Mumbai an ideal wedding destination is its cultural richness and architectural grandeur. Imagine exchanging vows against the backdrop of the city's iconic skyline, creating memories that will last a lifetime. Mumbai's wedding venues capture the essence of tradition and modernity, providing a perfect blend for your special day.

Whether you envision an intimate ceremony with close friends and family or a lavish celebration with hundreds of guests, Mumbai has venues to suit every size and style of wedding. The city's wedding venues are equipped with state-of-the-art amenities, ensuring that your big day is not only memorable but also hassle-free.

Mumbai's wedding venues offer a seamless blend of elegance and functionality. Many venues provide customizable packages to accommodate your specific needs and preferences, making the planning process more convenient for you. From pre-wedding ceremonies to the grand reception, these venues are well-equipped to host every aspect of your celebration.

In addition to the beautiful venues, Mumbai's wedding service providers, including decorators, caterers, and event planners, contribute to the city's reputation as a top wedding destination. Their expertise ensures that every detail is meticulously taken care of, allowing you to focus on enjoying your special day.

For those seeking a wedding venue that reflects the essence of Mumbai's charm, the options are as diverse as the city itself. From iconic hotels overlooking the Arabian Sea to historic landmarks with a touch of vintage elegance, Mumbai's wedding venues offer a canvas for you to paint your love story.

In conclusion, when it comes to choosing a wedding venue, Mumbai provides a plethora of options that cater to various tastes and preferences. The city's cultural richness, architectural beauty, and professional service providers make it an ideal destination to host the wedding of your dreams. So, if you're planning to tie the knot in Mumbai, explore the numerous wedding venues available and turn your special day into a magical experience that you and your guests will cherish forever. Wedding Venues In Mumbai, Wedding Venues In Mumbai, Wedding Venues In Mumbai, Wedding Venues In Mumbai, Wedding Venues In Mumbai, Wedding Venues In Mumbai.
</p>
<Footer/>

    </div>
  )
}

export default Venue
