import React from 'react'
import { Link } from 'react-router-dom'
import Logo from "../Asset/Logo.png";
import { ImLocation } from 'react-icons/im';
import { CiMail } from 'react-icons/ci';
import { BiPhoneCall } from 'react-icons/bi';
import { BsFacebook,BsInstagram  } from 'react-icons/bs';
import NavDropdown from "react-bootstrap/NavDropdown";
import "../css/footer.css"

const Footer = () => {
  return (
    <div>
      
      <footer className="footer-section">
        <div className="container">
          <div className="footer-cta pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i className="fa fa-map-marker" />
                  <div className="cta-text">
                    <h4>Find us</h4>
                   <a href="https://goo.gl/maps/Kad3z7VnW3erzccM9" target="_blank"><span>1508 15TH FLOOR, MAGIC SQUARE BUILDING DWARKADHISH MARG, Poddar Rd, Malad East, Mumbai, Maharashtra 400097</span></a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i className="fa fa-phone" />
                  <div className="cta-text">
                    <h4>Call us</h4>
                    <a href="tel:+91 70213 90953" target="_blank"><span>+91-9320060023</span></a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i className="fa fa-envelope-open" />
                  <div className="cta-text">
                    <h4>Mail us</h4>
                    <a href="mailto:shreekrishana09@gmail.com" target="_blank"><span>info@celebritydecorator.com</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <a href="#" style={{backgroundColor:"white" ,borderRadius:"25px" ,padding:"5px"}}>
                      <img
                        src={Logo}
                        className="img-fluid"
                        alt="logo"
                      />
                    </a>
                  </div>
                  <div className="footer-text">
                    <p>
                    A celebrity decorator crafts opulent, personalized interiors for high-profile clients. An event management company expertly organizes and executes a wide array of events to ensure a seamless and memorable experience for their clients and guests.
                    </p>
                  </div>
                  <div className="footer-social-icon">
                    <span>Follow us</span>
                    <a href="https://www.facebook.com/shree.krishnadigitalmarketing/">
                      <i className="fa fa-facebook facebook-bg" />
                    </a>
                    <a href="https://www.linkedin.com/company/shree-krishna-digital-marketing/?originalSubdomain=in">
                      <i className="fa fa-linkedin twitter-bg" />
                    </a>
                    <a href="https://www.instagram.com/shree.krishnadigitalmarketing/">
                      <i className="fa fa-instagram google-bg" />
                    </a>
                    <a href="https://wa.me/+917021390953">
                    <i class="fa fa-whatsapp watsapp-bg"/>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3 style={{color:"white "}}>Useful Links</h3>
                  </div>
                  <ul className='liststyle'>
                    <li>
                      <Link style={{color:"white"}} to="/">Home</Link>
                    </li>
                    <li>
                    <Link to='/wedding-services-company-malad' style={{color:"white"}}>Wedding Services</Link>
                    </li>
                  
                   
                    
                    <li>
                    <NavDropdown
                className=" textwhite paddingrl dropdown-hover"
                title="Decore"
                id="basic-nav-dropdown"
                style={{color:"white"}}
              >
                <NavDropdown.Item>
                  <Link to='/destination-wedding-company-malad' className='menuefontcolor' >Decore by destion</Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to='/wedding-decorator-company-malad' className='menuefontcolor'>Decore by event</Link>
                </NavDropdown.Item>

                <NavDropdown.Item>
                  <Link to='/wedding-theme-decorator-malad' className='menuefontcolor'>Decore by theme</Link>
                </NavDropdown.Item>

              </NavDropdown>
                    </li>
                    <li>
                    <Link to='/wedding-venues-mumbai' style={{color:"white"}}>Venues</Link>
                    </li>
                    <li>
                    <NavDropdown
                className="black paddingrl dropdown-hover "
                title="vendors"
                id="basic-nav-dropdown"
                style={{color:"white"}}
              >
                <NavDropdown.Item>
                  <Link to='/best-wedding-bartenders-mumbai ' className='menuefontcolor' >Bartenders</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to='/best-wedding-caterers-mumbai' className='menuefontcolor'>Caterers</Link></NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to='/wedding-invitation-cards-mumbai' className='menuefontcolor'>Invitattions cards & Wedding Stationery</Link> </NavDropdown.Item>

                <NavDropdown.Item>

                  <Link to='/makeup-artists-wedding-mumbai' className='menuefontcolor'>Makeup Artists </Link></NavDropdown.Item>

                <NavDropdown.Item> <Link to='/mehendi-artists-wedding-mumbai'  className='menuefontcolor'> Mahendi Artist</Link></NavDropdown.Item>
                <NavDropdown.Item> <Link to='/best-wedding-photographers-videographers-mumbai' className='menuefontcolor'>Photographers & Video Graphers</Link></NavDropdown.Item>

              </NavDropdown>
                    </li>
                    <li>
                    <NavDropdown
                className="black paddingrl dropdown-hover"
                title="Artist"
                id="basic-nav-dropdown"
                style={{color:"white"}}
               
              >
                <NavDropdown.Item><Link to='/wedding-reception-bands-mumbai' className='menuefontcolor'>Bands </Link></NavDropdown.Item>
                <NavDropdown.Item><Link to='/comedians-weddings-mumbai' className='menuefontcolor'>Comedians</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to='/best-djs-weddings-mumbai ' className='menuefontcolor'>DJ'S</Link></NavDropdown.Item>

                <NavDropdown.Item><Link to='/wedding-choreographer-hire-mumbai' className='menuefontcolor'> Dance Troupes and Choreographers</Link></NavDropdown.Item>

                <NavDropdown.Item><Link to='/wedding-host-mumbai' className='menuefontcolor'>Emcees </Link></NavDropdown.Item>
                
                <NavDropdown.Item><Link to='/swedding-singers-mumbai' className='menuefontcolor'>Singers</Link></NavDropdown.Item>
               
              </NavDropdown>
                    </li>
                    <li>
                    <Link to='/wedding-event-planner-mumbai' style={{color:"white"}}>About Us</Link>
                    </li>
                    <li>
                    <NavDropdown
                className="black paddingrl dropdown-hover"
                title="Wedding Destinations"
                id="basic-nav-dropdown"
                style={{color:"white"}}
              >
                <NavDropdown.Item>
                <Link to='/destination-wedding-karjat' className='menuefontcolor'>paramount resort</Link>
                 </NavDropdown.Item>
               
              </NavDropdown>
                    </li> 

                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3 className='textwhite'>Location</h3>
                  </div>
                  <div>
                  <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30150.395599341246!2d72.82414591965038!3d19.160254051925982!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b7fc8b63de33%3A0x2ee41c4013771ce6!2sCELEBRITY%20DECORATOR!5e0!3m2!1sen!2sin!4v1697175937208!5m2!1sen!2sin"
  
  style={{ border: 0 }}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/>

                  </div>
                
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 text-center text-lg-left">
                <div className="copyright-text">
                  <p>
                    Copyright © 2023, All Right Reserved{" "}
                    <a href="https://skdm.in">
                      Shree Krishna Digital Marketing
                    </a>
                  </p>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </footer>

    </div>
  )
}

export default Footer
