import React from 'react'
import banner1 from "../Asset/Banner/banner1.jpg"
import Footer from '../Components/Footer'
import { FaLocationDot } from 'react-icons/fa6';
import Enquiryform from '../Components/Enquiryform';
import comann from "../Asset/Banner/bannerdemo1.jpeg"
import { MetaTags } from 'react-meta-tags';
const DanceTAC = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
       <MetaTags>
        <title> Wedding Choreographer for Hire in Mumbai | Celebrity Decorator </title>
        <meta title="  Wedding Choreographer for Hire in Mumbai | Celebrity Decorator " />
        <meta name="description" content="Discover the perfect rhythm for your special day with our expert Wedding Choreographer for Hire in Mumbai. Transform your wedding into a dance-filled one." />
        <meta name="keywords" content="top-wedding-planner-decorator-malad,wedding-services-company-malad,destination-wedding-karjat,destination-wedding-company-malad,wedding-decorator-company-malad,wedding-theme-decorator-malad,wedding-venues-mumbai,best-wedding-bartenders-mumbai,best-wedding-caterers-mumbai,makeup-artists-wedding-mumbai,mehendi-artists-wedding-mumbai,wedding-invitation-cards-mumbai,best-wedding-photographers-videographers-mumbai,wedding-reception-bands-mumbai,comedians-weddings-mumbai,best-djs-weddings-mumbai,wedding-choreographer-hire-mumbai,wedding-host-mumbai,wedding-singers-mumbai,wedding-event-planner-mumbai" />
        <link rel="canonical" href="https://celebritydecorator.com/wedding-choreographer-hire-mumbai" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Wedding Choreographer for Hire in Mumbai | Celebrity Decorator " />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://celebritydecorator.com/" />
        <meta property="og:description" content="Discover the perfect rhythm for your special day with our expert Wedding Choreographer for Hire in Mumbai. Transform your wedding into a dance-filled one." />
        <meta property="og:image" content="https://skworld.in/static/media/logo.0a02f996c9ddeb40558c.png" />
      </MetaTags>
    <section id="breadcrumb-section" className="breadcrumb-section clearfix margintop">
<div
className="jarallax"
style={{ backgroundImage: "none", zIndex: 0 , backgroundImage: `url(${comann})` , }}

>
<div className="overlay-black">
<div className="container">
  <div className="row justify-content-center">
    <div className="col-lg-6 col-md-12 col-sm-12">
      {/* breadcrumb-title - start */}
      <div className="breadcrumb-title text-center mb-50">
        <span className="sub-title">Celebrity Decorator</span>
        <h2 className="big-title">
          <strong>Dance Troupes And Choreographers</strong> 
        </h2>
      </div>
      {/* breadcrumb-title - end */}
      {/* breadcrumb-list - start */}
      <div className="breadcrumb-list">
        <ul>
          <li className="breadcrumb-item">
            <a href="index-1.html" className="breadcrumb-link">
              Home
            </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            about us
          </li>
        </ul>
      </div>
      {/* breadcrumb-list - end */}
    </div>
  </div>
</div>
</div>
<div
id="jarallax-container-0"
style={{
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  overflow: "hidden",
  pointerEvents: "none",
  zIndex: -100
}}
>
<div
  style={{
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${banner1})` ,
    position: "fixed",
    top: 0,
    left: 0,

    height: "553.66px",
    overflow: "hidden",
    pointerEvents: "none",
    marginTop: "49.17px",
   
  }}
/>
</div>
</div>
</section>
<div >

<div className="grid container">
  <div className="row">
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/artistThumbnailImage/5bd97f8ab9160__PiahDanceAcademyWeddingChoreographer.png" alt="Snowy Mountains" />
            <div className="card__content">

              <h3 className="card__header" style={{margin:"0px"}}>Piah Dance Company</h3>
              <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/artistThumbnailImage/5bd98656d9971__Banjara-School-of-Dance.jpg" alt="Snowy Mountains" />
            <div className="card__content">
              <h3 className="card__header" style={{margin:"0px"}}>Banjara School</h3>
              <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    
  
  </div>
  
        
      </div>
<Enquiryform
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
</div>
<p style={{display:"none"}}>
Are you dreaming of a mesmerizing wedding celebration in Mumbai that is not only memorable but also filled with enchanting dance performances? Look no further – our professional wedding choreographer services are here to turn your dream into a reality.

In the bustling city of Mumbai, where traditions meet modernity, our team of skilled choreographers is dedicated to adding that perfect touch of grace and rhythm to your special day. With a passion for dance and a keen understanding of diverse cultural nuances, our choreographers are well-equipped to curate bespoke performances that reflect your unique love story.

Imagine a sangeet ceremony where every step tells a tale, and every move resonates with the joyous spirit of your union. Our experienced wedding choreographers in Mumbai specialize in creating choreographies that seamlessly blend tradition with contemporary dance styles, ensuring that your celebration is a perfect fusion of elegance and entertainment.

Whether you envision a Bollywood-inspired extravaganza, a classical dance showcase, or a trendy mix of various styles, our choreographers will work closely with you to understand your preferences and bring your vision to life. With their expertise, they can guide both novice and experienced dancers, ensuring that every participant feels comfortable and confident on the dance floor.

Our commitment to excellence is reflected in our meticulous attention to detail, from choreographing the grand couple's performance to coordinating group dances that involve family and friends. The result is a spectacular showcase of love and celebration, leaving an indelible mark on the hearts of your guests.

Embark on your wedding dance journey with us, and let our talented wedding choreographers in Mumbai transform your special day into a dance-filled extravaganza. Revel in the joy of dance, create lasting memories, and make your wedding a celebration that will be remembered for a lifetime. Contact us now to bring the magic of dance to your wedding festivities!






</p>
<Footer></Footer>
</div>
  )
}

export default DanceTAC
