import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles

import 'swiper/css/navigation';
import 'swiper/css/pagination';



// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
const Awords = () => {
  return (
    <div>
        <Swiper
         style={{
            height:"250px"
          }}
                slidesPerView={4}
                spaceBetween={30}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1000: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Navigation,Pagination]}
        className="mySwiper"
      >
      <section className='event3'>
<div className="container">
<h2 style={{textAlign:"center"}}>Press & Awards
</h2>
  <div className="row">
    <div className="col-md-3">
    <SwiperSlide>
      <div className='sliderimag'>
      <img src="https://www.dreamzkraft.com/images/awards/3.jpg"/>
      </div>

      </SwiperSlide>
    </div>
    <div className="col-md-3">
    <SwiperSlide>
      <div className='sliderimag'>
      <img src="https://www.dreamzkraft.com/images/awards/2.jpg"/>
      </div>
      
    </SwiperSlide>
    </div>
    <div className="col-md-3">
    <SwiperSlide>
      <div className='sliderimag'> 
      <img src="https://www.dreamzkraft.com/images/awards/1.jpg"/>
      </div>
     
    </SwiperSlide>
    </div>
    <div className="col-md-3">
    <SwiperSlide>
      <div className='sliderimag'>
      <img src="https://www.dreamzkraft.com/images/awards/4.jpg"/>
      </div>
      
      </SwiperSlide>
    </div>
    <div className="col-md-3">
    <SwiperSlide>
      <div className='sliderimag'>
      <img src="https://www.dreamzkraft.com/images/awards/3.jpg"/>
      </div>
      
      </SwiperSlide>
    </div>
    <div className="col-md-3">
    <SwiperSlide>
      <div className='sliderimag'>
      <img src="https://www.dreamzkraft.com/images/awards/2.jpg"/>
      </div>
   
    </SwiperSlide>
    </div>
    <div className="col-md-3">
    <SwiperSlide>
      <div className='sliderimag'>
      <img src="https://www.dreamzkraft.com/images/awards/1.jpg"/>
      </div>
      
    </SwiperSlide>
    </div>
    <div className="col-md-3">
    <SwiperSlide>
      <div className='sliderimag'>
      <img src="https://www.dreamzkraft.com/images/awards/4.jpg"/>
      </div>
   
      </SwiperSlide>
    </div>
  </div>
</div>
</section>
</Swiper>
    </div>
  )
}

export default Awords
