import React from 'react'
import banner1 from "../Asset/Banner/banner1.jpg"
import Footer from '../Components/Footer'
import { FaLocationDot } from 'react-icons/fa6';
import Enquiryform from '../Components/Enquiryform';
import comann from "../Asset/Banner/bannerdemo1.jpeg"
import { MetaTags } from 'react-meta-tags';
const Emcess = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
      <MetaTags>
        <title>Wedding Host in Mumbai | Celebrity Decorator </title>
        <meta title=" Wedding Host in Mumbai | Celebrity Decorator " />
        <meta name="description" content=" Discover the perfect Wedding Host in Mumbai to turn your special day into an unforgettable celebration. Professional and dedicated wedding hosts." />
        <meta name="keywords" content="top-wedding-planner-decorator-malad,wedding-services-company-malad,destination-wedding-karjat,destination-wedding-company-malad,wedding-decorator-company-malad,wedding-theme-decorator-malad,wedding-venues-mumbai,best-wedding-bartenders-mumbai,best-wedding-caterers-mumbai,makeup-artists-wedding-mumbai,mehendi-artists-wedding-mumbai,wedding-invitation-cards-mumbai,best-wedding-photographers-videographers-mumbai,wedding-reception-bands-mumbai,comedians-weddings-mumbai,best-djs-weddings-mumbai,wedding-choreographer-hire-mumbai,wedding-host-mumbai,wedding-singers-mumbai,wedding-event-planner-mumbai" />
        <link rel="canonical" href="https://celebritydecorator.com/top-wedding-planner-decorator-malad" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Wedding Host in Mumbai | Celebrity Decorator " />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://celebritydecorator.com/" />
        <meta property="og:description" content=" Discover the perfect Wedding Host in Mumbai to turn your special day into an unforgettable celebration. Professional and dedicated wedding hosts." />
        <meta property="og:image" content="https://skworld.in/static/media/logo.0a02f996c9ddeb40558c.png" />
      </MetaTags>
    <section id="breadcrumb-section" className="breadcrumb-section clearfix margintop">
<div
className="jarallax"
style={{ backgroundImage: "none", zIndex: 0 , backgroundImage: `url(${comann})` , }}

>
<div className="overlay-black">
<div className="container">
  <div className="row justify-content-center">
    <div className="col-lg-6 col-md-12 col-sm-12">
      {/* breadcrumb-title - start */}
      <div className="breadcrumb-title text-center mb-50">
        <span className="sub-title">Celebrity Decorator</span>
        <h2 className="big-title">
          <strong>Emcess</strong> 
        </h2>
      </div>
      {/* breadcrumb-title - end */}
      {/* breadcrumb-list - start */}
      <div className="breadcrumb-list">
        <ul>
          <li className="breadcrumb-item">
            <a href="index-1.html" className="breadcrumb-link">
              Home
            </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            about us
          </li>
        </ul>
      </div>
      {/* breadcrumb-list - end */}
    </div>
  </div>
</div>
</div>
<div
id="jarallax-container-0"
style={{
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  overflow: "hidden",
  pointerEvents: "none",
  zIndex: -100
}}
>
<div
  style={{
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${banner1})` ,
    position: "fixed",
    top: 0,
    left: 0,

    height: "553.66px",
    overflow: "hidden",
    pointerEvents: "none",
    marginTop: "49.17px",
   
  }}
/>
</div>
</div>
</section>
<div >

<div className="grid container">
  <div className="row">
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/artistThumbnailImage/5bd8374181d2b__KhyatiKava.jpg" alt="Snowy Mountains" />
            <div className="card__content">

              <h3 className="card__header" style={{margin:"0px"}}>Khayat Kava</h3>
              <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/artistThumbnailImage/5bd8359dd4e51__EmceeAKRahman.jpg" alt="Snowy Mountains" />
            <div className="card__content">
              <h3 className="card__header" style={{margin:"0px"}}>A K Rahman</h3>
              <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    
  
  </div>
  
        
      </div>
<Enquiryform
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
</div>
<p style={{display:"none"}}>
  
Planning a wedding in Mumbai is an exhilarating journey, and finding the right wedding host can significantly enhance the entire experience. A wedding host plays a pivotal role in ensuring that the event unfolds seamlessly, creating lasting memories for the couple and their guests.

In the vibrant city of Mumbai, where cultural diversity meets modernity, a skilled wedding host brings a unique blend of enthusiasm, charisma, and organizational expertise to the celebration. With their innate ability to connect with diverse audiences, a wedding host in Mumbai sets the tone for a joyous and unforgettable occasion.

From the moment guests arrive until the final farewell, the wedding host orchestrates the event with precision and flair. They are the maestro who harmonizes the various elements of the ceremony, including introductions, speeches, and interactive activities. Their role extends beyond mere announcements, as they engage the audience and create an atmosphere of warmth and celebration.

The key to a successful wedding lies in selecting a host who understands the pulse of Mumbai's dynamic culture. Whether it's incorporating local traditions or infusing a modern twist, the wedding host adapts to the couple's vision, making each moment truly special. Their ability to navigate the diverse cultural landscape of Mumbai ensures that every guest feels included and connected to the celebration.

In a city known for its hustle and bustle, a proficient wedding host is a calming presence, ensuring that the event unfolds seamlessly. Their attention to detail and proactive approach alleviate any stress, allowing the couple to bask in the joy of their special day. From managing the flow of events to handling unexpected surprises, the wedding host is a trusted guide, ensuring a smooth and enjoyable experience for everyone involved.

In conclusion, choosing the right wedding host in Mumbai is an investment in the success of your celebration. Their ability to connect with the audience, adapt to cultural nuances, and orchestrate the event with finesse creates a magical atmosphere that will be cherished for a lifetime.
</p>
<Footer></Footer>
</div>
  )
}

export default Emcess
