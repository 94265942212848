
import Slider from "./Slider";
import a1 from "../Asset/Banner/banner1.jpg";
import Testimnial from "../Components/testimnial";
import Footer from "../Components/Footer";
import "../css/venue.css";
import i1 from "../Asset/icon/icon2/i1.png";
import i2 from "../Asset/icon/icon2/i2.png";
import i3 from "../Asset/icon/icon2/i3.png";
import i4 from "../Asset/icon/icon2/i4.png";
import i5 from "../Asset/icon/icon2/i5.png";
import i7 from "../Asset/icon/i7.png";
import img1 from "../Asset/img1/n1.jpg";
import img2 from "../Asset/img1/n2.jpg";
import "../css/Home.css";
import Fade from "react-reveal/Fade";
import { CgNotes } from "react-icons/cg";
import { TbMoneybag } from "react-icons/tb";
import { GiNotebook } from "react-icons/gi";
import banner1 from "../Asset/Banner/banner2.jpg";
import banner2 from "../Asset/Banner/banner1.jpg";
import banner3 from "../Asset/Banner/banner3.jpg"
import Awords from "../Components/Awords";
import Service from "../Components/Service";
import Testimonial2 from "../Components/Testimonial2";
import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";
import Flip from "react-reveal/Flip";
import Enquery from "../Asset/Enquery.jpg"
import banner4 from "../Asset/BAN4.jpg"
import banner5 from "../Asset/BAN5.jpg"
import banner6 from  "../Asset/BAN6.jpg"
import banner7 from "../Asset/BAN7.jpg"
import React, { useEffect } from "react";
import lightGallery from "lightgallery";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-fullscreen.css";
import "lightgallery/css/lg-pager.css";
import "lightgallery/css/lg-autoplay.css";
import { MetaTags } from 'react-meta-tags';
const Home = () => {
  useEffect(() => {
    // Initialize lightGallery
    lightGallery(document.getElementById("image-gallery"), {
      mode: "lg-fade",
      thumbnail: true,
      zoom: false,
      autoplay: false,
    });
  }, []);

  return (
    <div>
        <MetaTags>
        <title>Top Wedding Planner & Decorator In Malad | Celebrity Decorator</title>
        <meta title=" Top Wedding Planner & Decorator In Malad | Celebrity Decorator" />
        <meta name="description" content=" India's Best Celebrity Management Agency in Mumbai SK World provides  the stars in your events with our unrivaled services across India." />
        <meta name="keywords" content="top-wedding-planner-decorator-malad,wedding-services-company-malad,destination-wedding-karjat,destination-wedding-company-malad,wedding-decorator-company-malad,wedding-theme-decorator-malad,wedding-venues-mumbai,best-wedding-bartenders-mumbai,best-wedding-caterers-mumbai,makeup-artists-wedding-mumbai,mehendi-artists-wedding-mumbai,wedding-invitation-cards-mumbai,best-wedding-photographers-videographers-mumbai,wedding-reception-bands-mumbai,comedians-weddings-mumbai,best-djs-weddings-mumbai,wedding-choreographer-hire-mumbai,wedding-host-mumbai,wedding-singers-mumbai,wedding-event-planner-mumbai" />
        <link rel="canonical" href="https://celebritydecorator.com/top-wedding-planner-decorator-malad" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Top Wedding Planner & Decorator In Malad | Celebrity Decorator" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://celebritydecorator.com/" />
        <meta property="og:description" content=" India's Best Celebrity Management Agency in Mumbai SK World provides  the stars in your events with our unrivaled services across India." />
        <meta property="og:image" content="https://skworld.in/static/media/logo.0a02f996c9ddeb40558c.png" />
      </MetaTags>
      <Slider/>

      {/* <section className="Home-banner">
        <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div>
            <div className="slider-item-content">

<span className="medium-text" style={{color:"black"}} >Expert Strategies in Event Management</span>
<small className="small-text" style={{color:"black"}}>"Turning Visions into Unforgettable Events: Your Event Management Partner</small>
  
 
</div>
            </div>
          </div>
          <div className="col-md-6">
            <div>
            <img className="imgbannersize1" src={banner1}/>
<img className="imgbannersize2" src={banner3}/>
<img className="imgbannersize3" src={banner2}/>
            </div>
          </div>
        </div>
        </div>
      </section> */}

      {/* /////////////////////////////// Section 2 //////////////////////////////// */}
      <section id="about" className="pt-14 pb-14">
        <div className="container">
          <div
            className="row align-items-start align-items-md-stretch"
            data-cues="fadeIn"
            data-disabled="true"
          >
            <div
              className="col-12 col-lg-6 order-1 order-lg-0"
              data-cue="fadeIn"
              data-show="true"
              style={{
                animationName: "fadeIn",
                animationDuration: "1000ms",
                animationTimingFunction: "ease",
                animationDelay: "0ms",
                animationDirection: "normal",
                animationFillMode: "both",
              }}
            >
              <Slide left>
                {/* Image */}
                <div className="row g-0">
                  <div className="col-6 order-md-1 order-2">
                    <figure className="mb-0 pe-3 pe-md-4 pe-lg-5">
                      <img
                        src={img1}
                        srcSet={img1}
                        className="img-fluid w-100 rounded shadow-sm"
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="col-6 order-md-1 order-2">
                    <figure className="mb-0 pt-9">
                      <img
                        src={img2}
                        srcSet={img2}
                        className="img-fluid w-100 rounded shadow-sm "
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
              </Slide>
              {/* /Image */}
            </div>
            <div
              className="col-12 col-lg-6 order-0 order-lg-1 order-md-2 order-1"
              data-cue="fadeIn"
              data-show="true"
              style={{
                animationName: "fadeIn",
                animationDuration: "1000ms",
                animationTimingFunction: "ease",
                animationDelay: "0ms",
                animationDirection: "normal",
                animationFillMode: "both",
              }}
            >
              {/* Description */}
              <Slide right>
                <div className="bg-white h-100 d-flex align-items-center">
                  <div className="p-lg-5 mb-8">
                    <span className="mb-2 fw-medium text-secondary ff-sub text-uppercase ls-1 d-block">
                      Welcome to
                    </span>
                    <h2 className=" ff-heading">
                      Celebrity Decorator
                    </h2>
                    <p className="mb-6">
                   
Premier event management company specializing in celebrity decoration services, offering the best wedding planning and decorating services in Malad. Our dedicated team ensures flawless execution and attention to detail for every occasion. With a focus on perfection, we bring your dream celebration to life. As the top choice for celebrity decorators, we create unforgettable experiences that reflect sophistication and style. Trust us to turn your special day into a magical and memorable event. Choose us as your preferred wedding planner and decorator in Malad for an unparalleled blend of creativity and expertise in celebrity event decoration.
                    </p>

                    <a
                      role="button"
                      tabIndex={0}
                      href="#!"
                      className="custom-btn "
                    >
                      <Link to="/wedding-event-planner-mumbai" style={{ color: "white" }}>
                        Read More
                      </Link>
                    </a>
                  </div>
                </div>
              </Slide>
              {/* /Description */}
            </div>
          </div>
        </div>
      </section>
      <section id="rooms" className="pt-14 pb-14 bg-body" data-bs-theme="dark">
        <div className="container">
          {/* Description */}
          <div
            className="mb-10 text-center"
            data-cue="fadeIn"
            data-show="true"
            style={{
              animationName: "fadeIn",
              animationDuration: "1000ms",
              animationTimingFunction: "ease",
              animationDelay: "0ms",
              animationDirection: "normal",
              animationFillMode: "both",
            }}
          >
            <h2 className=" ff-heading mb-2 textcolor ">
              Wedding &amp; Services
            </h2>
            <h4 className="h6 fw-normal text-body-secondary ff-sub text-uppercase ">
            Elevating events with celebrity-style decor and service.
            </h4>
          </div>
          {/* /Description */}
          {/* Rooms List */}
          <div className="tns-outer" id="tns1-ow">
            <div
              className="tns-controls"
              aria-label="Carousel Navigation"
              tabIndex={0}
            ></div>

            <div id="tns1-mw" className="tns-ovh">
              <div className="tns-inner" id="tns1-iw">
                <div
                  className="room-luxury-slider  tns-slider tns-carousel tns-subpixel tns-calc tns-horizontal"
                  data-cue="fadeIn"
                  id="tns1"
                  style={{
                    animationName: "fadeIn",
                    animationDuration: "1000ms",
                    animationTimingFunction: "ease",
                    animationDelay: "0ms",
                    animationDirection: "normal",
                    animationFillMode: "both",
                  }}
                  data-show="true"
                >
                  <Service />
                </div>
              </div>
            </div>
          </div>
          {/* /Rooms List */}
        </div>
      </section>

      <section id="facilitiesAmenities" className="pt-14 pb-10">
        <div className="container">
          {/* Heading */}
          <div
            className="mb-10 text-center"
            data-cue="fadeIn"
            data-show="true"
            style={{
              animationName: "fadeIn",
              animationDuration: "1000ms",
              animationTimingFunction: "ease",
              animationDelay: "0ms",
              animationDirection: "normal",
              animationFillMode: "both",
            }}
          >
            <h2 className="ff-heading mb-2">Amenity</h2>
            <p className="h6 fw-medium text-body-secondary ff-sub text-uppercase ">
            Elevate events with celebrity-inspired flair.
            </p>
          </div>
          {/* /Heading */}
          {/* List */}
          <div className="row" data-cues="fadeIn" data-disabled="true">
            <Flip left>
              <div
                className="col-12 col-xl-4 col-md-6"
                data-cue="fadeIn"
                data-show="true"
                style={{
                  animationName: "fadeIn",
                  animationDuration: "1000ms",
                  animationTimingFunction: "ease",
                  animationDelay: "0ms",
                  animationDirection: "normal",
                  animationFillMode: "both",
                }}
              >
                {/* Item */}
                <div className="mb-5">
                  <div className="d-flex align-items-start">
                    <div className="fs-1 text-primary pe-5 lh-sm">
                      <img style={{ width: "100px" }} src={i1} alt="" />
                    </div>
                    <div>
                      <h3 className="h6 text-uppercase ff-sub ls-1">
                      friendly team
                      </h3>
                      <p>
                        Vix placerat no, eam cu bonorum sententiae, adipisci
                        atomorum duo noster ad vel
                      </p>
                    </div>
                  </div>
                </div>
                {/* /Item */}
              </div>
            </Flip>
            <Flip left>
              <div
                className="col-12 col-xl-4 col-md-6"
                data-cue="fadeIn"
                data-show="true"
                style={{
                  animationName: "fadeIn",
                  animationDuration: "1000ms",
                  animationTimingFunction: "ease",
                  animationDelay: "0ms",
                  animationDirection: "normal",
                  animationFillMode: "both",
                }}
              >
                {/* Item */}
                <div className="mb-5">
                  <div className="d-flex align-items-start">
                    <div className="fs-1 text-primary pe-5 lh-sm">
                      <img style={{ width: "100px" }} src={i2} alt="" />
                    </div>
                    <div>
                      <h3 className="h6 text-uppercase ff-sub ls-1">
                      Unique Scenario
                      </h3>
                      <p>
                        Scripta apeirian in his. Quo accusa complect eu, errem
                        adipisci ocurreret an per
                      </p>
                    </div>
                  </div>
                </div>
                {/* /Item */}
              </div>
            </Flip>
            <Flip left>
              <div
                className="col-12 col-xl-4 col-md-6"
                data-cue="fadeIn"
                data-show="true"
                style={{
                  animationName: "fadeIn",
                  animationDuration: "1000ms",
                  animationTimingFunction: "ease",
                  animationDelay: "0ms",
                  animationDirection: "normal",
                  animationFillMode: "both",
                }}
              >
                {/* Item */}
                <div className="mb-5">
                  <div className="d-flex align-items-start">
                    <div className="fs-1 text-primary pe-5 lh-sm">
                      <img style={{ width: "100px" }} src={i3} alt="" />
                    </div>
                    <div>
                      <h3 className="h6 text-uppercase ff-sub ls-1">
                      perfect venues
                      </h3>
                      <p>
                        An quis eligendi duo, placerat latine veritus vim
                        placerat. Duo errem latine scribentur
                      </p>
                    </div>
                  </div>
                </div>
                {/* /Item */}
              </div>
            </Flip>
            <Flip left>
              <div
                className="col-12 col-xl-4 col-md-6"
                data-cue="fadeIn"
                data-show="true"
                style={{
                  animationName: "fadeIn",
                  animationDuration: "1000ms",
                  animationTimingFunction: "ease",
                  animationDelay: "0ms",
                  animationDirection: "normal",
                  animationFillMode: "both",
                }}
              >
                {/* Item */}
                <div className="mb-5">
                  <div className="d-flex align-items-start">
                    <div className="fs-1 text-primary pe-5 lh-sm">
                      <img style={{ width: "100px" }} src={i4} alt="" />
                    </div>
                    <div>
                      <h3 className="h6 text-uppercase ff-sub ls-1">
                        {" "}
                        24/7 hours support
                      </h3>
                      <p>
                        Has et magna dolore dicit. No placerat essent inermis
                        nec prima homero similique pri at.
                      </p>
                    </div>
                  </div>
                </div>
                {/* /Item */}
              </div>
            </Flip>
            <Flip left>
              <div
                className="col-12 col-xl-4 col-md-6"
                data-cue="fadeIn"
                data-show="true"
                style={{
                  animationName: "fadeIn",
                  animationDuration: "1000ms",
                  animationTimingFunction: "ease",
                  animationDelay: "0ms",
                  animationDirection: "normal",
                  animationFillMode: "both",
                }}
              >
                {/* Item */}
                <div className="mb-5">
                  <div className="d-flex align-items-start">
                    <div className="fs-1 text-primary pe-5 lh-sm">
                      <img style={{ width: "100px" }} src={i5} alt="" />
                    </div>
                    <div>
                      <h3 className="h6 text-uppercase ff-sub ls-1">
                      Unforgetable Times
                      </h3>
                      <p>
                        Mel graece consequ cu, ex dicunt appareat duo, vim errem
                        adipisci augue placerat.
                      </p>
                    </div>
                  </div>
                </div>
                {/* /Item */}
              </div>
            </Flip>
            <Flip left>
              <div
                className="col-12 col-xl-4 col-md-6"
                data-cue="fadeIn"
                data-show="true"
                style={{
                  animationName: "fadeIn",
                  animationDuration: "1000ms",
                  animationTimingFunction: "ease",
                  animationDelay: "0ms",
                  animationDirection: "normal",
                  animationFillMode: "both",
                }}
              >
                {/* Item */}
                <div className="mb-5">
                  <div className="d-flex align-items-start">
                    <div className="fs-1 text-primary pe-5 lh-sm">
                      <img style={{ width: "100px" }} src={i7} alt="" />
                    </div>
                    <div>
                      <h3 className="h6 text-uppercase ff-sub ls-1">
                      Briliant Idea
                      </h3>
                      <p>
                        Ne ius assentior, te soluta facilisis mel placerat
                        appareat duo dicunt splendide quo no.
                      </p>
                    </div>
                  </div>
                </div>
                {/* /Item */}
              </div>
            </Flip>
          </div>
          {/* /List */}
        </div>
      </section>
      <section className="Home-Section-5">
        <div className="Form-Section">
          <div className="Form-Image-con">
            <Fade left>
              <div className="Form-Image">
                <img
                  src={Enquery}
                  alt=""
                  className="react-reveal"
                  style={{
                    animationFillMode: "both",
                    animationDuration: "1000ms",
                    animationDelay: "0ms",
                    animationIterationCount: 1,
                    opacity: 1,
                    animationName: "react-reveal-264739228441732-4",
                  }}
                />
              </div>
            </Fade>
          </div>
          <Fade right>
            <div
              className="react-reveal Form-Data-con"
              style={{
                animationFillMode: "both",
                animationDuration: "1000ms",
                animationDelay: "0ms",
                animationIterationCount: 1,
                opacity: 1,
                animationName: "react-reveal-264739228441732-5",
              }}
            >
              <div className="Form-Data">
                <div className="Form-Info">
                 
                
                  <h2 className="ff-heading mb-2" style={{marginBottom:"0px"}}>Enquiry Now</h2>
                  <h6 className="mb-2"  style={{fontSize:" 1.06875rem"}}>SIGN UP</h6>
                  <p>
                    For the latest inspiration and insider tips straight to your
                    inbox.
                  </p>
                </div>
                <div className="formitem ">

              
                <div className="Form-Inputs">
                  <form className="HomeForm">
                    <label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Your Full Name"
                      />
                    </label>
                    <label>
                      <input
                        type="text"
                        name="email"
                        placeholder="Your email address"
                      />
                    </label>
                    <label>
                      <input
                        type="number"
                        name="name"
                        placeholder="Your Contact Number"
                      />
                    </label>
                    <button className="form-submit-button">Submit</button>
                  </form>
                </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </section>
      <section id="facilitiesAmenities" className="pt-14 pb-10">
        <div className="container">
          {/* Heading */}
          <div
            className="mb-10 text-center"
            data-cue="fadeIn"
            data-show="true"
            style={{
              animationName: "fadeIn",
              animationDuration: "1000ms",
              animationTimingFunction: "ease",
              animationDelay: "0ms",
              animationDirection: "normal",
              animationFillMode: "both",
            }}
          >
            <h2 className=" ff-heading mb-2">Gallery</h2>
            <p className="h6 fw-medium text-body-secondary ff-sub text-uppercase ">
              An quis eligendi atomorum latine
            </p>
          </div>
          {/* /Heading */}
          {/* List */}
          <div id="image-gallery" className="row">
      <a
        className="col-md-4 "
        style={{paddingBottom:"20px"}}
        href={Enquery}
        data-lg-size="1600-2400"
        data-lg-srcset={Enquery}
      >
        <img src={Enquery} alt="Enquery" />
      </a>
      <a
        className="col-md-4"
        style={{paddingBottom:"20px"}}
        href={banner1}
        data-lg-size="1600-2400"
        data-lg-srcset={banner1}
      >
        <img src={banner1} alt="Banner 1" />
      </a>
      <a
        className="col-md-4"
        style={{paddingBottom:"20px"}}
        href={banner6}
        data-lg-size="1600-2400"
        data-lg-srcset={banner6}
      >
        <img src={banner6} alt="Banner 6" />
      </a>
      <a
        className="col-md-4"
        style={{paddingBottom:"20px"}}
        href={banner7}
        data-lg-size="1600-2400"
        data-lg-srcset={banner7}
      >
        <img src={banner7} alt="Banner 7" />
      </a>
      <a
        className="col-md-4"
        style={{paddingBottom:"20px"}}
        href={banner4}
        data-lg-size="1600-2400"
        data-lg-srcset={banner4}
      >
        <img src={banner4} alt="Banner 4" />
      </a>
      <a
        className="col-md-4"
        style={{paddingBottom:"20px"}}
        href={banner5}
        data-lg-size="1600-2400"
        data-lg-srcset={banner5}
      >
        <img src={banner5} alt="Banner 5" />
      </a>
    </div>
<div style={{display:"flex",justifyContent:"center",textAlign:"center"}}>
<a
                      role="button"
                      tabIndex={0}
                      href="#!"
                      className="custom-btn "
                    >
                      <Link to="/Galleryer" style={{ color: "white" }}>
                        Read More
                      </Link>
                    </a>
</div>
          {/* /List */}
        </div>
      </section>
      <section id="facilitiesAmenities" className="pt-14 pb-10">
        <div className="container">
          {/* Heading */}
          <div
            className="mb-10 text-center"
            data-cue="fadeIn"
            data-show="true"
            style={{
              animationName: "fadeIn",
              animationDuration: "1000ms",
              animationTimingFunction: "ease",
              animationDelay: "0ms",
              animationDirection: "normal",
              animationFillMode: "both",
            }}
          >
            <h2 className=" ff-heading mb-2">Testimonial</h2>
            <p className="h6 fw-medium text-body-secondary ff-sub text-uppercase ">
            Dream events, flawlessly executed.
            </p>
          </div>
          {/* /Heading */}
          {/* List */}
          <Testimonial2 />
          {/* /List */}
        </div>
      </section>
      <section
        id="facilitiesAmenities"
        className="pt-14 pb-10"
        style={{ backgroundColor: "whitesmoke" }}
      >
        <div className="container">
          {/* Heading */}
          <div
            className="mb-10 text-center"
            data-cue="fadeIn"
            data-show="true"
            style={{
              animationName: "fadeIn",
              animationDuration: "1000ms",
              animationTimingFunction: "ease",
              animationDelay: "0ms",
              animationDirection: "normal",
              animationFillMode: "both",
            }}
          >
            <h2 className="ff-heading mb-2">Awards</h2>
            <p className="h6 fw-medium text-body-secondary ff-sub text-uppercase ">
            Award-worthy events, perfected with flair
            </p>
          </div>
          {/* /Heading */}
          {/* List */}
          <Awords />
          {/* /List */}
        </div>
      </section>
<p style={{display:"none"}}>
If you're in search of the top wedding planner and decorator in Malad, look no further! Our team is dedicated to turning your dream wedding into a reality. As the premier wedding planner and decorator in Malad, we pride ourselves on creating unforgettable moments that will be cherished for a lifetime.

Our experienced team of professionals understands the significance of your special day and strives to make it truly magical. With our expertise in wedding planning and decoration, we ensure that every detail is meticulously taken care of, leaving you stress-free to enjoy the celebration.

As the go-to wedding planner and decorator in Malad, we offer a wide range of services tailored to your specific needs. From conceptualizing and planning the entire event to executing the finest details of decoration, we are committed to making your wedding day absolutely perfect.

Imagine a wedding where every element reflects your unique style and personality. That's precisely what our top-notch team aims to achieve. We are not just wedding planners and decorators; we are storytellers who craft narratives through impeccable designs and seamless execution.

Choosing the right wedding planner and decorator in Malad is crucial to ensuring a smooth and delightful journey from engagement to the grand finale. With our dedicated team by your side, you can relax and savor every moment, knowing that we are here to make your dreams come true.

So, whether you envision a classic, elegant affair or a modern, vibrant celebration, our top wedding planner and decorator in Malad are ready to bring your vision to life. Contact us today to start planning the wedding of your dreams with the most trusted professionals in the industry. Your perfect day is just a step away with the top wedding planner and decorator in Malad.</p>
      <Footer></Footer>
    </div>
  );
};

export default Home;
