
import './App.css';
import Home from "./pages/Home"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Hadder from './Components/Hadder';
import Aboutus from './pages/Aboutus';

import "./css/Style.css"
import Decore from './pages/Decore';
import Decorebydestion from './pages/Decorebydestion';
import Decorebyevent from './pages/Decorebyevent';
import Decorebytheme from './pages/Decorebytheme';
import Venue from './pages/Venue';
import Bartenders from './pages/Bartenders';
import Caterers from './pages/Caterers';
import MakeupArtists from './pages/MakeupArtists';
import Invitattionscard from './pages/Invitattionscards&WeddingStationery';
import MahendiArtist from './pages/MahendiArtist';
import Photographers from './pages/Photographers&VideoGraphers';
import Bands from './pages/Bands';
import Comedians from './pages/Comedians';
import DJS from './pages/DJS.jsx';
import DanceTAC from './pages/DanceTAC.jsx';
import Emcess from './pages/Emcess';
import Singers from './pages/Singers';
import Weddingservice from './pages/Weddingservice';
import PeramountResort from './pages/PeramountResort';
import Galleryer from './pages/Gallery';
import Blog from "../src/pages/Blogs.jsx"
import What from './Components/whatsapp.jsx';
function App() {
  return (
   <>
     <BrowserRouter> 
      <Hadder/>
      <What></What>
      <Routes>

     <Route path='/' element={<Home/>}></Route>
     <Route path='/wedding-event-planner-mumbai' element={<Aboutus/>}></Route>
     <Route path='/Decore' element={<Decore/>}></Route>
     <Route path='/destination-wedding-company-malad' element={<Decorebydestion/>}></Route>
     <Route path='/wedding-decorator-company-malad' element={<Decorebyevent/>}></Route>
     <Route path='/wedding-theme-decorator-malad' element={<Decorebytheme/>}></Route>
     <Route path='/wedding-venues-mumbai' element={<Venue/>}></Route>
     <Route path='/best-wedding-bartenders-mumbai' element={<Bartenders/>}></Route>
     <Route path='/best-wedding-caterers-mumbai' element={<Caterers/>}></Route>
     <Route path='/makeup-artists-wedding-mumbai' element={<MakeupArtists/>}></Route>
     <Route path='/wedding-invitation-cards-mumbai' element={<Invitattionscard/>}></Route>
     <Route path='/mehendi-artists-wedding-mumbai' element={<MahendiArtist/>}></Route>
     <Route path='/best-wedding-photographers-videographers-mumbai' element={<Photographers/>}></Route>
     <Route path='/wedding-reception-bands-mumbai' element={<Bands/>}></Route>
     <Route path='/comedians-weddings-mumbai' element={<Comedians/>}></Route>
     <Route path='/best-djs-weddings-mumbai' element={<DJS/>}></Route>
     <Route path='/wedding-choreographer-hire-mumbai' element={<DanceTAC/>}></Route>
     <Route path='/wedding-host-mumbai' element={<Emcess/>}></Route>
     <Route path='/swedding-singers-mumbai' element={<Singers/>}></Route>
     <Route path='/wedding-services-company-malad' element={<Weddingservice/>}></Route>
     <Route path='/destination-wedding-karjat' element={<PeramountResort/>}></Route>
     <Route path='/Galleryer' element={<Galleryer/>}></Route>
     <Route path='/BLOG' element={<Blog/>}></Route>
     </Routes>

     </BrowserRouter>
   </>
  );
}

export default App;
