import React from 'react'
import banner1 from "../Asset/Banner/banner1.jpg"
import Footer from '../Components/Footer'
import { FaLocationDot } from 'react-icons/fa6';
import Enquiryform from '../Components/Enquiryform';
import comann from "../Asset/Banner/bannerdemo1.jpeg"
import { MetaTags } from 'react-meta-tags';
const Bands = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
       <MetaTags>
        <title> Wedding Reception Bands In Mumbai | Celebrity Decorator </title>
        <meta title="  Wedding Reception Bands In Mumbai | Celebrity Decorator " />
        <meta name="description" content="Discover the perfect harmony for your special day with the finest Wedding Reception Bands in Mumbai. Elevate your celebration with enchanting melodies." />
        <meta name="keywords" content="top-wedding-planner-decorator-malad,wedding-services-company-malad,destination-wedding-karjat,destination-wedding-company-malad,wedding-decorator-company-malad,wedding-theme-decorator-malad,wedding-venues-mumbai,best-wedding-bartenders-mumbai,best-wedding-caterers-mumbai,makeup-artists-wedding-mumbai,mehendi-artists-wedding-mumbai,wedding-invitation-cards-mumbai,best-wedding-photographers-videographers-mumbai,wedding-reception-bands-mumbai,comedians-weddings-mumbai,best-djs-weddings-mumbai,wedding-choreographer-hire-mumbai,wedding-host-mumbai,wedding-singers-mumbai,wedding-event-planner-mumbai" />
        <link rel="canonical" href="https://celebritydecorator.com/wedding-reception-bands-mumbai" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Wedding Reception Bands In Mumbai | Celebrity Decorator " />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://celebritydecorator.com/" />
        <meta property="og:description" content="Discover the perfect harmony for your special day with the finest Wedding Reception Bands in Mumbai. Elevate your celebration with enchanting melodies." />
        <meta property="og:image" content="https://skworld.in/static/media/logo.0a02f996c9ddeb40558c.png" />
      </MetaTags>
    <section id="breadcrumb-section" className="breadcrumb-section clearfix margintop">
<div
className="jarallax"
style={{ backgroundImage: "none", zIndex: 0 , backgroundImage: `url(${comann})` , }}

>
<div className="overlay-black">
<div className="container">
  <div className="row justify-content-center">
    <div className="col-lg-6 col-md-12 col-sm-12">
      {/* breadcrumb-title - start */}
      <div className="breadcrumb-title text-center mb-50">
        <span className="sub-title">Celebrity Decorator</span>
        <h2 className="big-title">
          <strong>Bands</strong> 
        </h2>
      </div>
      {/* breadcrumb-title - end */}
      {/* breadcrumb-list - start */}
      <div className="breadcrumb-list">
        <ul>
          <li className="breadcrumb-item">
            <a href="index-1.html" className="breadcrumb-link">
              Home
            </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            about us
          </li>
        </ul>
      </div>
      {/* breadcrumb-list - end */}
    </div>
  </div>
</div>
</div>
<div
id="jarallax-container-0"
style={{
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  overflow: "hidden",
  pointerEvents: "none",
  zIndex: -100
}}
>
<div
  style={{
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${banner1})` ,
    position: "fixed",
    top: 0,
    left: 0,

    height: "553.66px",
    overflow: "hidden",
    pointerEvents: "none",
    marginTop: "49.17px",
   
  }}
/>
</div>
</div>
</section>
<div >

<div className="grid container">
  <div className="row">
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/artistThumbnailImage/5bdaf397af520__Sanam-TheBand.jpg" alt="Snowy Mountains" />
            <div className="card__content">

              <h3 className="card__header" style={{margin:"0px"}}>Sanam - The Band</h3>
              <h6><span style={{color:"#ffbe30"}}><FaLocationDot/><span style={{padding:"3px"}}></span></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    <div className="col-md-4">
    <div className="grid__item">
          <div className="card">
            <img className="card__img" src="https://www.dreamzkraft.com/admin/artistThumbnailImage/5bdaf36bd1a6b__Nasha-the-Band.jpg" alt="Snowy Mountains" />
            <div className="card__content">
              <h3 className="card__header" style={{margin:"0px"}}>Nasha The Band</h3>
              <h6><span style={{color:"#ffbe30"}}><FaLocationDot/></span ><span style={{padding:"3px"}}></span>Mumbai</h6>
              <p className="card__text">
                Look up at the night sky, and find yourself 
              </p>
              <a
                        role="button"
                        tabIndex={0}
                        href="#!"
                        className="custom-btn "
                        style={{padding:"13px 24px"}}
                        onClick={() => setModalShow(true)}
                      >
                      Enquiry Now
                      </a>
            </div>
          </div>
        </div>
    </div>
    
  
  </div>
  
        
      </div>
<Enquiryform
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
</div>
<p style={{display:"none"}}>
  
Planning a wedding reception in Mumbai and searching for the perfect musical ensemble to set the tone for your celebration? Look no further! Mumbai boasts a vibrant and diverse music scene, making it an ideal destination for finding the ideal wedding reception band. With a plethora of talented musicians and performers, you can easily tailor the musical ambiance to suit your preferences.

A wedding reception band in Mumbai adds a unique and lively touch to your event, creating an unforgettable experience for you and your guests. Whether you're envisioning a soulful melody or upbeat tunes to keep the dance floor buzzing, Mumbai's music scene has it all. These talented bands are adept at playing a variety of genres, from traditional Indian melodies to contemporary chart-toppers, ensuring a musical journey that resonates with your taste.

The city offers an array of options to suit different budgets and preferences, allowing you to customize your musical experience. From classical instrumental ensembles to high-energy Bollywood bands, Mumbai's musicians are skilled in creating the perfect ambiance for your special day. Engage your guests with the soul-stirring sounds of live music and create memories that will last a lifetime.

To make your search more efficient, consider utilizing online platforms or local recommendations to discover the ideal wedding reception band in Mumbai. Explore the diverse talents within the city's music community and find the perfect match that aligns with your vision for the celebration.

In conclusion, Mumbai's dynamic music scene provides an excellent backdrop for your wedding reception. Let the rhythm and melodies of a skilled wedding reception band elevate your celebration, ensuring a memorable and melodious experience for you and your guests.






</p>
<Footer></Footer>
</div>
  )
}

export default Bands
